import campainsBanner from '../assets/images/campainsBanner.png'
import campanis_subbanner from '../assets/images/campains_subbanner.png'
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "../assets/css/Campains.css"; // Import custom CSS for styling

import Schwarmintelligenz_carousal from '../assets/images/Schwarmintelligenz_carousal.png'
import Schwarmintelligenz_carousal2 from '../assets/images/Schwarmintelligenz_carousal2.png'
import schwarm_main from '../assets/images/schwarm_main.jpg'
import schwarm_sub1 from '../assets/images/schwarm_sub1.jpg'
import schwarm_sub2 from '../assets/images/schwarm_sub2.jpg'
import schwarm_sub3 from '../assets/images/schwarm_sub3.jpg'

import flora_funa_main from '../assets/images/flora_funa_main.jpg'
import flora_funa_sub1 from '../assets/images/flora_funa_sub1.jpg'
import flora_funa_sub2 from '../assets/images/flora_funa_sub2.jpg'
import flora_funa_sub3 from '../assets/images/flora_funa_sub3.jpg'

import bluehendes_wunder_main from '../assets/images/bluehendes_wunder_main.jpg'
import bluehendes_wunder_sub1 from '../assets/images/bluehendes_wunder_sub1.jpg'
import bluehendes_wunder_sub2 from '../assets/images/bluehendes_wunder_sub2.jpg'
import bluehendes_wunder_sub3 from '../assets/images/bluehendes_wunder_sub3.jpg'

import artenglueck_main from '../assets/images/artenglueck_main.jpg'
import artenglueck_sub1 from '../assets/images/artenglueck_sub1.jpg'
import artenglueck_sub2 from '../assets/images/artenglueck_sub2.jpg'
import artenglueck_sub3 from '../assets/images/artenglueck_sub3.jpg'

import greenbusiness_main from '../assets/images/greenbusiness_main.jpg'
import greenbusiness_sub1 from '../assets/images/greenbusiness_sub1.jpg'
import greenbusiness_sub2 from '../assets/images/greenbusiness_sub2.jpg'
import greenbusiness_sub3 from '../assets/images/greenbusiness_sub3.jpg'

import schwarmintelligenz from '../assets/pdf/schwarmintelligenz.pdf';
import schwarmintelligenz_en from '../assets/pdf/schwarmintelligenz-en.pdf';
import flora_und_fauna from '../assets/pdf/flora_und_fauna.pdf';
import flora_und_fauna_en from '../assets/pdf/flora_fauna-en.pdf';
import bluehendes_wunder from '../assets/pdf/bluehendes_wunder.pdf';
import bluehendes_wunder_en from '../assets/pdf/bluehendes_wunder-en.pdf';

import artenglueck_xl from '../assets/pdf/artenglueck_xl.pdf';
import artenglueck_xl_en from '../assets/pdf/artenglueck_xl-en.pdf';

import green_business from '../assets/pdf/green_business.pdf';
import green_business_en from '../assets/pdf/green_business-en.pdf';



import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function Campains() {

  const { t, i18n } = useTranslation('campains');

  const { t: tcampains, i18n: i18ncampains } = useTranslation('campains');

  const currentLanguage = i18n.language;

  return (
    <>

      {/* banner section */}
      <div className='HeaderBanner_img'>
        <img src={campainsBanner} alt='...' className='w-100' style={{ height: '100%' }} />
      </div>

      <div className='text-white text-center'>
        <p className='fw-normal fs-2 mt-5 mb-0'>{tcampains("banner").title1}</p>
        <h2 style={{ color: '#CBD62E' }} className='pb-4'>{tcampains("banner").title2}</h2>
        <p className='fs-5 w-75 mx-auto'>{tcampains("banner").content1}</p>
        <p className='fs-5 w-75 mx-auto pb-3'>{tcampains("banner").content2}</p>
        <p className='fs-5 w-75 mx-auto' style={{ color: '#CBD62E' }}>{tcampains("banner").content3}</p>
      </div>

      <div className='container py-4'>
        <hr style={{ border: '2px solid #CBD62E', width: '10%', opacity: 1 }} className='m-0 mx-auto my-3' />
        <h2 className='text-center ' style={{ color: '#CBD62E' }}>Welches Modell passt zu Ihrem Unternehmen?</h2>
        <div className='d-flex justify-content-center py-4'>
          <ul class="nav nav-pills mb-3 spl-tabs scrollable-list" id="pills-tab" role="tablist">
            <li class="nav-item p-1" role="presentation">
              <button class="nav-link active" id="pills-SCHWARMINTELLIGENZ-tab" data-bs-toggle="pill" data-bs-target="#pills-SCHWARMINTELLIGENZ" type="button" role="tab" aria-controls="pills-SCHWARMINTELLIGENZ" aria-selected="true"><b>SCHWARMINTELLIGENZ</b></button>
            </li>
            <li class="nav-item p-1" role="presentation">
              <button class="nav-link" id="pills-FLORA_FAUNA-tab" data-bs-toggle="pill" data-bs-target="#pills-FLORA_FAUNA" type="button" role="tab" aria-controls="pills-FLORA_FAUNA" aria-selected="false"><b>FLORA & FAUNA</b></button>
            </li>
            <li class="nav-item p-1" role="presentation">
              <button class="nav-link" id="pills-genuss-tab" data-bs-toggle="pill" data-bs-target="#pills-genuss" type="button" role="tab" aria-controls="pills-genuss" aria-selected="false"><b>BLÜHENDES WUNDER</b></button>
            </li>
            <li class="nav-item p-1" role="presentation">
              <button class="nav-link" id="pills-imkerei-tab" data-bs-toggle="pill" data-bs-target="#pills-imkerei" type="button" role="tab" aria-controls="pills-imkerei" aria-selected="false"><b>ARTENGLÜCK XL</b></button>
            </li>
            <li class="nav-item p-1" role="presentation">
              <button class="nav-link" id="pills-kreation-tab" data-bs-toggle="pill" data-bs-target="#pills-kreation" type="button" role="tab" aria-controls="pills-kreation" aria-selected="false"><b>GREEN BUSINESS</b></button>
            </li>

          </ul>
        </div>
      </div>
      <div class="tab-content container" id="pills-tabContent " style={{ marginTop: '-90px', backgroundColor: '#222C35' }}>
        <div class="tab-pane fade show active text-white p-5 camp-tabs" id="pills-SCHWARMINTELLIGENZ" role="tabpanel" aria-labelledby="pills-SCHWARMINTELLIGENZ-tab">
          {/* <div className='row py-4' >
            <div className='col-md' style={{ overflow: 'hidden' }}>
              <img src={Schwarmintelligenz_carousal} className="img-fluid" alt="Responsive image" />
            </div>
            <div className='col-md-3' style={{ overflow: 'hidden' }}>
              <img src={testimg} className="img-fluid pb-3" alt="Responsive image" />
              <img src={testimg} className="img-fluid pb-3" alt="Responsive image" />
              <img src={testimg} className="img-fluid" alt="Responsive image" />
            </div>
          </div> */}
          <div className='tab_content'>
            <div className='row py-4'>
              <div className='col-9 col-md' style={{ overflow: 'hidden' }}>
                <img src={schwarm_main} className="img-fluid" alt="Responsive image" />
              </div>
              <div className='col col-md-3 d-md-block ' style={{ overflow: 'hidden' }}>
                <img src={schwarm_sub1} className="img-fluid pb-3" alt="Responsive image" />
                <img src={schwarm_sub2} className="img-fluid pb-3" alt="Responsive image" />
                <img src={schwarm_sub3} className="img-fluid" alt="Responsive image" />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h2>{tcampains("tab_section").tab1_title}</h2>
                <p className='fs-5'>{tcampains("tab_section").tab1_content}</p>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                <h2>{tcampains("tab_section").price} 1.490 €</h2>
              </div>
            </div>
            <hr style={{ border: '2px solid #CBD62E', width: '100%', opacity: 1 }} className='m-0 mx-auto my-3' />
            <div className='row'>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab1_col1_title}</h4>
                <p className='fs-6'>{tcampains("tab_section").tab1_col1_content}</p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab1_col2_title}</h4>
                <p className='fs-6'>{tcampains("tab_section").tab1_col2_content}</p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab1_col3_title}</h4>
                <p className='fs-6'>{tcampains("tab_section").tab1_col3_content}</p>
              </div>

              <div className='d-flex justify-content-end flex-wrap'>
                <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/contact" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tcampains("tab_section").button}</Link></button>
                <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}>

                  <a
                    href={currentLanguage == 'de' ? schwarmintelligenz : schwarmintelligenz_en}

                    className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" download>Download Info</a></button>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-pane fade text-white p-5 camp-tabs" id="pills-FLORA_FAUNA" role="tabpanel" aria-labelledby="pills-FLORA_FAUNA-tab">
          <div className='tab_content'>
            <div className='row py-4'>
              <div className='col-9 col-md' style={{ overflow: 'hidden' }}>
                <img src={flora_funa_main} className="img-fluid" alt="Responsive image" />
              </div>
              <div className='col col-md-3 d-md-block ' style={{ overflow: 'hidden' }}>
                <img src={flora_funa_sub1} className="img-fluid pb-3" alt="Responsive image" />
                <img src={flora_funa_sub2} className="img-fluid pb-3" alt="Responsive image" />
                <img src={flora_funa_sub3} className="img-fluid" alt="Responsive image" />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h2>{tcampains("tab_section").tab2_title}</h2>
                <p className='fs-5'>{tcampains("tab_section").tab2_content}
                </p>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                <h2>{tcampains("tab_section").price} 3490 €</h2>
              </div>
            </div>
            <hr style={{ border: '2px solid #CBD62E', width: '100%', opacity: 1 }} className='m-0 mx-auto my-3' />
            <div className='row'>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab2_col1_title}</h4>
                <p className='fs-6'>{tcampains("tab_section").tab2_col1_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab2_col2_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab2_col2_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab2_col3_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab2_col3_content1}
                </p>
                <p className='fs-6 fw-bold'>
                  {tcampains("tab_section").tab2_col3_content2}
                </p>
              </div>
            </div>
            <div className='d-flex justify-content-end flex-wrap'>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/contact" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tcampains("tab_section").button}</Link></button>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><a
                href={currentLanguage == 'de' ? flora_und_fauna : flora_und_fauna_en}

                className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" download>Download Info</a></button>
            </div>
          </div>
        </div>
        <div class="tab-pane fade text-white p-5 camp-tabs" id="pills-genuss" role="tabpanel" aria-labelledby="pills-genuss-tab">
          <div className='tab_content'>
            <div className='row py-4'>
              <div className='col-9 col-md' style={{ overflow: 'hidden' }}>
                <img src={bluehendes_wunder_main} className="img-fluid" alt="Responsive image" />
              </div>
              <div className='col col-md-3 d-md-block ' style={{ overflow: 'hidden' }}>
                <img src={bluehendes_wunder_sub1} className="img-fluid pb-3" alt="Responsive image" />
                <img src={bluehendes_wunder_sub2} className="img-fluid pb-3" alt="Responsive image" />
                <img src={bluehendes_wunder_sub3} className="img-fluid" alt="Responsive image" />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h2>{tcampains("tab_section").tab3_title}</h2>
                <p className='fs-5'>{tcampains("tab_section").tab3_content}
                </p>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                <h2>{tcampains("tab_section").price} 1.490 €</h2>
              </div>
            </div>
            <hr style={{ border: '2px solid #CBD62E', width: '100%', opacity: 1 }} className='m-0 mx-auto my-3' />
            <div className='row'>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab3_col1_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab3_col1_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab3_col2_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab3_col2_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>
                  {tcampains("tab_section").tab3_col3_title}
                </h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab3_col3_content}

                </p>

              </div>
            </div>
            <div className='d-flex justify-content-end flex-wrap'>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/contact" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >jetzt anfragen</Link></button>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><a
                // href={bluehendes_wunder}
                href={currentLanguage == 'de' ? bluehendes_wunder : bluehendes_wunder_en}
                className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" download>Download Info</a></button>
            </div>
          </div>
        </div>
        <div class="tab-pane fade text-white p-5 camp-tabs" id="pills-imkerei" role="tabpanel" aria-labelledby="pills-imkerei-tab">
          <div className='tab_content'>
            <div className='row py-4'>
              <div className='col-9 col-md' style={{ overflow: 'hidden' }}>
                <img src={artenglueck_main} className="img-fluid" alt="Responsive image" />
              </div>
              <div className='col col-md-3 d-md-block ' style={{ overflow: 'hidden' }}>
                <img src={artenglueck_sub1} className="img-fluid pb-3" alt="Responsive image" />
                <img src={artenglueck_sub2} className="img-fluid pb-3" alt="Responsive image" />
                <img src={artenglueck_sub3} className="img-fluid" alt="Responsive image" />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h2>{tcampains("tab_section").tab4_title}</h2>
                <p className='fs-5'>{tcampains("tab_section").tab4_content}
                </p>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                <h2>{tcampains("tab_section").price} 7.490 €</h2>
              </div>
            </div>
            <hr style={{ border: '2px solid #CBD62E', width: '100%', opacity: 1 }} className='m-0 mx-auto my-3' />
            <div className='row'>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab4_col1_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab4_col1_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab4_col2_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab4_col2_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab4_col3_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab4_col3_content}
                </p>

              </div>
            </div>
            <div className='d-flex justify-content-end flex-wrap'>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/contact" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tcampains("tab_section").button}</Link></button>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><a
                // href={artenglueck_xl} 
                href={currentLanguage == 'de' ? artenglueck_xl : artenglueck_xl_en}

                className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" download>Download Info</a></button>
            </div>
          </div>
        </div>
        <div class="tab-pane fade text-white p-5 camp-tabs" id="pills-kreation" role="tabpanel" aria-labelledby="pills-kreation-tab">
          <div className='tab_content'>
            <div className='row py-4'>
              <div className='col-9 col-md' style={{ overflow: 'hidden' }}>
                <img src={greenbusiness_main} className="img-fluid" alt="Responsive image" />
              </div>
              <div className='col col-md-3 d-md-block ' style={{ overflow: 'hidden' }}>
                <img src={greenbusiness_sub1} className="img-fluid pb-3" alt="Responsive image" />
                <img src={greenbusiness_sub2} className="img-fluid pb-3" alt="Responsive image" />
                <img src={greenbusiness_sub3} className="img-fluid" alt="Responsive image" />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8'>
                <h2>{tcampains("tab_section").tab5_title}</h2>
                <p className='fs-5'>
                  {tcampains("tab_section").tab5_content}
                </p>
              </div>
              <div className='col-md-4 d-flex align-items-center justify-content-end'>
                <h2>{tcampains("tab_section").price} 2.490 €</h2>
              </div>
            </div>
            <hr style={{ border: '2px solid #CBD62E', width: '100%', opacity: 1 }} className='m-0 mx-auto my-3' />
            <div className='row'>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab5_col1_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab5_col1_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab5_col2_title}</h4>
                <p className='fs-6'>
                  {tcampains("tab_section").tab5_col2_content}
                </p>
              </div>
              <div className='col-md-4 '>
                <h4>{tcampains("tab_section").tab5_col3_title}</h4>
                <p className='fs-6'>{tcampains("tab_section").tab5_col3_content}
                </p>

              </div>
            </div>
            <div className='d-flex justify-content-end flex-wrap'>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="/contact" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" >{tcampains("tab_section").button}</Link></button>
              <button className='btn rounded-0 m-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><a 
              href={currentLanguage == 'de' ? green_business : green_business_en}

              className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark fs-5" aria-disabled="true" download>Download Info</a></button>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
}
