import React from 'react'
import imprintBanner from '../assets/images/imprint-banner-img.png'
import '../assets/css/DataProtection.css'


export default function DataProtection() {
    return (
        <>
            {/* header section */}
            <div className='imprint-banner' style={{ height: '250px' }}>
                <div className='text-center text-white container' style={{ paddingTop: '130px', position: 'relative', zIndex: 10 }}>
                    <h2 className='text-uppercase'>Datenschutzerklärung</h2>
                    {/* <img src={imprintBanner} className='text-line' style={{
                        float: 'right',
                        marginTop: '-101px',
                        width: '159px',
                        marginRight: '131px'
                    }} /> */}
                </div>
            </div>

            {/* contente section */}

            <div className='container imprint-section  p-5 text-white'>
                <div className='w-75'>
                    <h3>Datenschutz</h3>
                    <h3>1. Datenschutz auf einen Blick</h3>
                    <h5>Allgemeine Hinweise</h5>
                    <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                        passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
                        identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
                        diesem Text aufgeführten Datenschutzerklärung.</p>
                    <h5>Datenerfassung auf dieser Website</h5>
                    <h5>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h5>
                    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
                        dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p>
                    <h5>Wie erfassen wir Ihre Daten?</h5>
                    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
                        können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>

                    <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
                    <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                        personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser
                        Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung
                        jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die
                        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
                    <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p>
                    <h3>2. Hosting</h3>
                    <h5>Externes Hosting</h5>
                    <p>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die auf
                        dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-
                        Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
                        Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln.</p>
                    <p>Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
                        bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
                        Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern
                        eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
                        Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
                        auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die
                        Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
                        ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
                    <h6>Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
                        ist und unsere Weisungen in Bezug auf diese Daten befolgen.</h6>
                    <p>rackSPEED GmbH<br />
                        Reisholzer Werftstraße 31b<br />
                        Eingang 35<br />
                        40589 Düsseldorf</p>

                    <p>Tel.: +49 (0)211-93673737<br />
                        eMail: info(at)rackspeed.de</p>
                    <p>Auftragsverarbeitung</p>
                    <p>Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei
                        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die
                        personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
                        DSGVO verarbeitet.</p>
                    <h3>3. Allgemeine Hinweise und Pflicht­informationen</h3>
                    <h5>Datenschutz</h5>
                    <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                        personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
                        Datenschutzerklärung.</p>
                    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                        Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
                        erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
                        geschieht.</p>
                    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
                        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                    <h5>Hinweis zur verantwortlichen Stelle</h5>
                    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
                    <p>ZOGI Nature GmbH<br />
                        Gutenbergstraße 2<br />
                        75210 Keltern</p>
                    <p>Telefon: +49 7236 98286 - 0<br />
                        E-Mail: nature@zogi.biz</p>
                    <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die
                        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
                        entscheidet.</p>
                    <h5>Speicherdauer</h5>
                    <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
                        personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
                        Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
                        gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten
                        haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
                        Fortfall dieser Gründe.</p>
                    <h5>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</h5>
                    <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
                        Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach
                        Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
                        personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs.
                        1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät
                        (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25
                        Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur
                        Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1
                        lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung
                        erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage
                        unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall
                        einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.</p>
                    <h5>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h5>
                    <p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich
                        nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten
                        übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU
                        vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
                        personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen
                        gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste)
                        Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
                        Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.</p>
                    <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
                    <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits
                        erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
                        bleibt vom Widerruf unberührt.</p>
                    <h5>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21
                        DSGVO)</h5>
                    <p>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
                        HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN,
                        GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT
                        AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE,
                        AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE
                        WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
                        VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
                        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG
                        DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
                        (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
                    <p>WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO
                        HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER
                        PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR
                        DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
                        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
                        ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p>
                    <h5>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h5>
                    <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde,
                        insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des
                        mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
                        gerichtlicher Rechtsbehelfe.</p>
                    <h5>Recht auf Daten­übertrag­barkeit</h5>
                    <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
                        verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
                        Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit
                        es technisch machbar ist.</p>
                    <h5>Auskunft, Löschung und Berichtigung</h5>
                    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
                        über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
                        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
                        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>
                    <h5>Recht auf Einschränkung der Verarbeitung</h5>
                    <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
                        können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
                        Fällen:</p>
                    <ul>
                        <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der
                            Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der
                            Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                        <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
                            Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
                        <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
                            oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
                            Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                        <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren
                            und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
                            haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
                    </ul>
                    <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer
                        Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
                        Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
                        werden.</p>
                    <h5>SSL- bzw. TLS-Verschlüsselung</h5>
                    <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
                        Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine
                        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“
                        wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                    <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
                        Dritten mitgelesen werden.</p>
                    <h3>4. Datenerfassung auf dieser Website</h3>
                    <h5>Cookies</h5>
                    <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem
                        Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies)
                        oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
                        Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst
                        löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.</p>
                    <p>Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere
                        Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen
                        des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).</p>
                    <p>Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
                        Websitefunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
                        Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.</p>
                    <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von
                        Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur
                        Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f
                        DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
                        berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten
                        Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren
                        Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser
                        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur
                        im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das
                        automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
                        kann die Funktionalität dieser Website eingeschränkt sein.</p>
                    <p>Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im
                        Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
                    <h5>Einwilligung mit Usercentrics</h5>
                    <p>Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre Einwilligung zur Speicherung bestimmter
                        Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform
                        zu dokumentieren. Anbieter dieser Technologie ist die Usercentrics GmbH, Sendlinger Straße 7, 80331 München,
                        Website: <sapn ><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://usercentrics.com/de/'>https://usercentrics.com/de/</a></sapn> (im Folgenden „Usercentrics“).</p>
                    <p>Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten an Usercentrics übertragen:</p>

                    <ul>
                        <li>Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)</li>
                        <li>Ihre IP-Adresse</li>
                        <li>Informationen über Ihren Browser</li>
                        <li>Informationen über Ihr Endgerät</li>
                        <li>Zeitpunkt Ihres Besuchs auf der Website</li>
                    </ul>

                    <p>Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw. deren
                        Widerruf zuordnen zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, das
                        Usercentrics-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche
                        Aufbewahrungspflichten bleiben unberührt.</p>
                    <p>Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz bestimmter
                        Technologien einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.</p>
                    <h6>Auftragsverarbeitung</h6>
                    <p>Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter geschlossen. Hierbei
                        handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die
                        personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
                        DSGVO verarbeitet.</p>
                    <h5>Server-Log-Dateien</h5>
                    <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
                        Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                    <ul>
                        <li>Browsertyp und Browserversion</li>
                        <li>verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>Uhrzeit der Serveranfrage</li>
                        <li>IP-Adresse</li>
                    </ul>
                    <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
                    <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
                        berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu
                        müssen die Server-Log-Files erfasst werden.</p>
                    <h5>Kontaktformular</h5>
                    <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular
                        inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
                        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                    <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
                        Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In
                        allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der
                        an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                        sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern,
                        Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
                        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere
                        Aufbewahrungsfristen – bleiben unberührt.</p>
                    <h5>Anfrage per E-Mail, Telefon oder Telefax</h5>
                    <p>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden
                        personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
                        verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
                    <p>Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der
                        Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In
                        allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der
                        an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
                        sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
                        auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B.
                        nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
                        gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
                    <h3>5. Soziale Medien</h3>
                    <h5>Instagram</h5>
                    <p>Auf dieser Website sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten
                        durch die Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.</p>
                    <p>Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät und dem
                        Instagram-Server hergestellt. Instagram erhält dadurch Informationen über den Besuch dieser Website durch Sie.</p>
                    <p>Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des Instagram-Buttons die
                        Inhalte dieser Website mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch dieser Website
                        Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
                        der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.</p>
                    <p>Soweit eine Einwilligung (Consent) eingeholt wurde, erfolgt der Einsatz des o. g. Dienstes auf Grundlage von Art. 6
                        Abs. 1 lit. a DSGVO und § 25 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Soweit keine Einwilligung eingeholt
                        wurde, erfolgt die Verwendung des Dienstes auf Grundlage unseres berechtigten Interesses an einer möglichst
                        umfassenden Sichtbarkeit in den Sozialen Medien.</p>
                    <p>Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website erfasst und an
                        Facebook bzw. Instagram weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4 Grand Canal
                        Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26
                        DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei ausschließlich auf die Erfassung der Daten und
                        deren Weitergabe an Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende Verarbeitung durch
                        Facebook bzw. Instagram ist nicht Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
                        Verpflichtungen wurden in einer Vereinbarung über gemeinsame Verarbeitung festgehalten. Den Wortlaut der
                        Vereinbarung finden Sie unter: <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://www.facebook.com/legal/controller_addendum'>https://www.facebook.com/legal/controller_addendum.</a></span> Laut dieser Vereinbarung
                        sind wir für die Erteilung der Datenschutzinformationen beim Einsatz des Facebook- bzw. Instagram-Tools und für
                        die datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für die
                        Datensicherheit der Facebook bzw. Instagram-Produkte ist Facebook verantwortlich. Betroffenenrechte (z. B.
                        Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram verarbeiteten Daten können Sie direkt bei
                        Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir verpflichtet, diese an
                        Facebook weiterzuleiten.</p>
                    <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
                        Sie hier: <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://www.facebook.com/legal/EU_data_transfer_addendum'></a></span><span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://www.facebook.com/legal/EU_data_transfer_addendum'>https://www.facebook.com/legal/EU_data_transfer_addendum,</a></span><br />
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://help.instagram.com/519522125107875'>https://help.instagram.com/519522125107875</a></span> und <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://de-de.facebook.com/help/566994660333381'>https://de-de.facebook.com/help/566994660333381.</a></span></p>
                    <p>Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://instagram.com/about/legal/privacy/.' target='_blank'>https://instagram.com/about/legal/privacy/.</a></span></p>

                    <h5>6. Plugins und Tools</h5>
                    <p>YouTube mit erweitertem Datenschutz</p>
                    <p>Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited („Google“),
                        Gordon House, Barrow Street, Dublin 4, Irland.</p>
                    <p>Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass YouTube keine
                        Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video ansehen. Die
                        Weitergabe von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus hingegen nicht
                        zwingend ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine Verbindung
                        zum Google DoubleClick-Netzwerk her.</p>
                    <p>Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den Servern von YouTube
                        hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in
                        Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen
                        Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.</p>
                    <p>Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern oder
                        vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen. Auf diese Weise kann
                        YouTube Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um
                        Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.</p>
                    <p>Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge ausgelöst
                        werden, auf die wir keinen Einfluss haben.</p>
                    <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies
                        stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                        abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
                        Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im
                        Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
                        widerrufbar.</p>
                    <p>Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung unter:
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://policies.google.com/privacy?hl=de'>https://policies.google.com/privacy?hl=de.</a></span></p>
                    <h5>Vimeo ohne Tracking (Do-Not-Track)</h5>
                    <p>Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York,
                        New York 10011, USA.</p>
                    <p>Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von
                        Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem
                        erlangt Vimeo Ihre IP-Adresse. Wir haben Vimeo jedoch so eingestellt, dass Vimeo Ihre Nutzeraktivitäten nicht
                        nachverfolgen und keine Cookies setzen wird.</p>
                    <p>Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt
                        ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
                        abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
                        Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage
                        von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier: https://vimeo.com/privacy.</p>
                    <p>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter:<br />
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://vimeo.com/privacy'>https://vimeo.com/privacy.</a></span></p>
                    <h5>Google Web Fonts (lokales Hosting)</h5>
                    <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
                        bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet dabei
                        nicht statt.</p>
                    <p>Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der
                        Datenschutzerklärung von Google: <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://policies.google.com/privacy?hl=de' className='anchor'>https://policies.google.com/privacy?hl=de.</a></span></p>
                    <h5>https://policies.google.com/privacy?hl=de.</h5>
                    <p>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten Font Awesome. Font Awesome ist lokal installiert.
                        Eine Verbindung zu Servern von Fonticons, Inc. findet dabei nicht statt.</p>

                    <p>Weitere Informationen zu Font Awesome finden Sie in der Datenschutzerklärung für Font Awesome unter:<br />
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://fontawesome.com/privacy' className='anchor'>https://fontawesome.com/privacy.</a></span>
                    </p>
                    <h5>Google Maps</h5>
                    <p>Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited („Google“), Gordon
                        House, Barrow Street, Dublin 4, Irland.</p>
                    <p>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen
                        werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser
                        Seite hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke
                        der einheitlichen Darstellung der Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr
                        Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
                    <p>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und
                        an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
                        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
                        erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
                        soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers
                        (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
                    <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
                        Sie hier: <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://privacy.google.com/businesses/gdprcontrollerterms/' className='anchor'>https://privacy.google.com/businesses/gdprcontrollerterms/</a></span> und<br />
                        <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://privacy.google.com/businesses/gdprcontrollerterms/sccs/' className='anchor'>https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.</a></span>
                        <p>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:
                            <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://policies.google.com/privacy?hl=de' className='anchor'>https://policies.google.com/privacy?hl=de.</a></span></p>

                        <h5>Google reCAPTCHA</h5>
                        <p>Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf dieser Website. Anbieter ist die Google
                            Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.</p>
                        <p>Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf dieser Website (z. B. in einem Kontaktformular)
                            durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das
                            Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der
                            Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z. B. IP-
                            Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die
                            bei der Analyse erfassten Daten werden an Google weitergeleitet.</p>
                        <p>Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf hingewiesen,
                            dass eine Analyse stattfindet.</p>
                        <p>Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
                            hat ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und vor
                            SPAM zu schützen. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
                            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
                            Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting)
                            im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>

                        <p>Weitere Informationen zu Google reCAPTCHA entnehmen Sie den Google-Datenschutzbestimmungen und den<br />
                            Google Nutzungsbedingungen unter folgenden Links: <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://policies.google.com/privacy?hl=de' className='anchor'>https://policies.google.com/privacy?hl=de</a></span> und<br />
                            <span><a style={{ color: '#B9D738', wordWrap: 'break-word', wordBreak: 'break-all' }} href='https://policies.google.com/terms?hl=de' className='anchor'>https://policies.google.com/terms?hl=de.</a></span></p>
                    </p>
                </div>
            </div>
        </>
    )
}
