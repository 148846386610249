import LocalizedStrings from "react-localization";

const String = {
  en: {
    banner: {
      title: "YOUR SUSTAINABILITY",
      about: "About",
      micro_factories: "Micro Factories",
      services: "Services",
      zogi_nature_partners:"Zogi Nature Partners'",
      contact:'Contact',
      shop_now:'Show Now'
    },
    footer: {
      how: "How do you want your egg today?",
      boiledEgg: "Boiled egg",
      softBoiledEgg: "Soft-boiled egg",
      choice: "How to choose the egg",
    },
  },
  gr: {
    banner: {
        title: "Ihre Nachhaltigkeit ist unsere Mission",
        about: "About",
        micro_factories: "Nachhaltigkeitsmanufaktur",
        services: "Services",
        zogi_nature_partners:"Zogi Nature Partners'",
        contact:'Contact',
        shop_now:'Show Now'
      },
      footer: {
        how: "How do you want your egg today?",
        boiledEgg: "Boiled egg",
        softBoiledEgg: "Soft-boiled egg",
        choice: "How to choose the egg",
      },
  },
};

export let homeLang = new LocalizedStrings(String);
