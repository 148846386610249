import React from 'react'
import '../assets/css/HomeFooter.css';
import footerLogo from '../assets/images/footer_logo.png'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function HomeFooter() {

    const { t, i18n } = useTranslation('footer');



    return (
        <>
            {/* <div className='mt-5'>
                <hr className='text-white' />

                <div className='container-fluid pt-5'>
                    <div className='text-center'>
                        <span className='fw-normal' style={{
                            width: '10%',
                            height: '3px',
                            backgroundColor: '#CBD62E',
                            position: 'absolute',
                            left: '45%'
                        }}> </span>
                    </div>
                    <h1 className='text-center pt-2' style={{ color: '#CBD62E' }}>LET'S TALK</h1>
                    <div className='row w-50 mx-auto mt-3 p-5 pt-0'>
                        <div className='col-12'>
                            <input type='text' className=' form-control m-1 mb-3 rounded-0 p-3' placeholder='Name' />
                        </div>

                        <div className='col-12'>
                            <input type='text' className='form-control m-1 mb-3 rounded-0 p-3' placeholder='Email' />
                        </div>
                        <div className='col-12'>
                            <input type='text' className=' form-control m-1 mb-3 rounded-0 p-3' placeholder='Company Name' />
                        </div>
                        <div className='col-12'>
                            <textarea className='form-control text-white m-1 mb-3 rounded-0 p-5 ps-3' placeholder='Your Message'></textarea>
                        </div>
                        <button className='btn btn-md w-25 p-2  rounded-0 mx-auto m-2' style={{ backgroundColor: '#B9D738' }}>Submit</button>
                    </div>
                    <div className=''>
                    <div className='text-center p-2'>
                            <a><i class="fa-brands fa-facebook-f text-white fs-2"></i></a>
                            <a><i class="fa-brands fa-instagram ps-4 text-white fs-2"></i></a>
                            <a><i class="fa-brands fa-linkedin-in ps-4 text-white fs-2" ></i></a>

                        </div>
                        <div className="container d-flex justify-content-center align-items-center p-4">
                            <img src={footerLogo} className="img-fluid footer-logo w-25" alt="Centered Image" />
                        </div>
                        <p className='text-center text-white fs-4 fw-normal'>Am Sohlweg 26 // 76297 Stutensee // DE Germany</p>
                        <p className='text-center text-white '>Copyright 2024 ZOGI Nature GmbH - All Rights Reserved</p>
                    </div>
                </div>
            </div> */}

            <div className='mt-5'>
                <hr className='text-white' />

                <div className='container-fluid pt-5 '>
                    {/* <div className='text-center'>
                    <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto ' />
                    </div>
                    <h2 className='text-center py-3' style={{ color: '#CBD62E', textTransform:'uppercase'  }}>{t("menu").lets_talk}</h2> */}


                    <div className='text-center mt-4'>
                        <img src={footerLogo} className="" alt="Footer Logo" style={{ width: '300px' }} />
                        <div className='text-center mt-4'>
                        <a href='#' className='text-white fs-4 me-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width={18}><path fill='white' d="M279.1 288l14.2-92.7h-88.9v-60.1c0-25.4 12.4-50.1 52.2-50.1h40.4V6.3S260.4 0 225.4 0c-73.2 0-121.1 44.4-121.1 124.7v70.6H22.9V288h81.4v224h100.2V288z" /></svg>
                        </a>
                        <a href='#' className='text-white fs-4 me-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={27}><path fill='white' d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                        </a>
                        <a href='#' className='text-white fs-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={25}><path fill='white' d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                        </a>
                        </div>
                        <p className='text-white fs-5 mt-3'>ZOGI NATURE GmbH // Gutenbergstraße 2 // 75210 Keltern</p>
                        <div className='text-center text-white mt-4'>
                            <ul class="list-inline list-unstyled">
                                <Link to="/imprint" className='text-white'><li class="list-inline-item ">{t("menu").imprint}</li></Link>
                                <Link to="/data_protection" className='text-white'><li class="list-inline-item ps-4 ">{t("menu").data_protection}</li></Link>
                                <Link to="/terms" className='text-white'><li class="list-inline-item ps-4 ">{t("menu").terms}</li></Link>
                            </ul>
                        </div>
                        <p className='text-white mb-0 py-2'>Copyright 2024 ZOGI Nature GmbH - All Rights Reserved</p>
                    </div>
                </div>
            </div>

        </>
    )
}
