import LocalizedStrings from "react-localization";

const String = {
    en: {
        header: {
            home: "Home",
            about: "About",
            micro_factories: "Micro Factories",
            services: "Services",
            zogi_nature_partners: "Zogi Nature Partners'",
            contact: 'Contact',
            shop_now: 'Show Now'
        },
        banner: {
            title: "YOUR SUSTAINABILITY",
            sub_title: 'is Our Mission',
            enquiry_now: "Enquiry Now",
            watch_now: " Watch Now",
        },
        mission:{
            title:"Together with Foresight",
            content:`With ZOGI Nature's full-service sustainability campaign, you become a
            partner in a unique micro-factory circulation system. Benefit from a holistic
            service package and tailor-made service.`
        },
        footer: {
            how: "How do you want your egg today?",
            boiledEgg: "Boiled egg",
            softBoiledEgg: "Soft-boiled egg",
            choice: "How to choose the egg",
        },
    },
    gr: {
        header: {
            home: "Home",
            about: "Über uns",
            micro_factories: "Nachhaltigkeitsmanufaktur",
            services: "Leistungen",
            zogi_nature_partners: "Zogi Nature Nachhaltigkeitspartner",
            contact: 'Kontakt',
            shop_now: 'Show Now'
        },
        banner: {
            title: "Ihre Nachhaltigkeit",
            sub_title: "ist unsere Mission",
            enquiry_now: "Sie uns",
            watch_now: " Kontaktieren",
        },
        mission:{
            title:"Gemeinsam mit Weitblick",
            content:`Mit Ihrer Full-Service Nachhaltigkeitskampagne bei Zogi Nature werden Sie Partner unseres einzigartigen Kreislaufsystems. Wir unterstützen Sie bei Ihrer Transformation in Richtung Nachhaltigkeit und machen Ihre CSR sichtbar und greifbar.
            Nutzen Sie die Vorteile der umfassenden Dienstleistungspakete und der maßgeschneiderten Leistungen.
            `
        },
        footerString: {
            how: "Come vuoi il tuo uovo oggi?",
            boiledEgg: "Uovo sodo",
            softBoiledEgg: "Uovo alla coque",
            choice: "Come scegliere l'uovo",
        },
    },
};

export let layoutLang = new LocalizedStrings(String);
