import React, { useEffect, useState } from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

import carousalImg1 from '../assets/images/factorycarousal1.png'
import carousalImg2 from '../assets/images/factorycarousal2.png'
import carousalImg3 from '../assets/images/factorycarousal3.png'
import carousalImg4 from '../assets/images/factorycarousal4.png'
import carousalImg5 from '../assets/images/factorycarousal5.png'


import { NavLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import bestSeller_rewe from '../assets/images/bestSeller_rewe.png'
import bestSeller_chocolate from '../assets/images/bestSeller_chocolate.png'
import bestSeller_beeswe from '../assets/images/bestSeller_beeswe.png'
import bestSeller_bee from '../assets/images/bestSeller_bee.png'
import bestSeller_bildmaterial from '../assets/images/bestSeller_bildmaterial.jpg'
import bestSeller_milk from '../assets/images/bestSeller_milk.jpg'
import bestSeller_ihrdesign from '../assets/images/bestSeller_ihrdesign.jpg'
import bestSeller_honigbox from '../assets/images/bestSeller_honigbox.jpg'
import bestSeller_beeswebird from '../assets/images/bestSeller_beeswebird.jpg'
import bestSeller_artenglueck from '../assets/images/bestSeller_artenglueck.jpg'
import bestSeller_avocado from '../assets/images/bestSeller_avocado.jpg'



import { Link } from 'react-router-dom';

export default function FactoryCarousal() {
    const { t, i18n } = useTranslation('home');
    const { t: tHome, i18n: i18nHome } = useTranslation('home');
    const [advdata, setAdvdata] = useState(null)
    const [sellerdata, setSellerdata] = useState(null)


    const currentLanguage = i18nHome.language;


    useEffect(() => {

        const fetchAdvertiseData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/advertising/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setAdvdata(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchSellerData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/best_sellers/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setSellerdata(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        fetchAdvertiseData();
        fetchSellerData();

    }, [currentLanguage])

    console.log('data', sellerdata)

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className='mt-5 text-white'>

                <div className='container pt-0'>
                    <Carousel className='factory_carousal' responsive={responsive} infinite={true}>
                        {/* <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_bildmaterial} alt="" className='' />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>feine Tees.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>Hot & Icey</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_milk} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>power shot of the day</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>Golden Milk</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_ihrdesign} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>delikatessen.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>my Kitchen Saver</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_honigbox} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>imkerei.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>Honig Geschenkboxen</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_beeswebird} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>vielfalt.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>DIY Vogelfutterhaus</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_artenglueck} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>wachstum.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>Artenglück Samenbriefchen</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className='p-2'>
                            <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                <div class="img-card image-card-hover text-white rounded-0"><img src={bestSeller_avocado} alt="" />
                                    <div class="image-card-overlay"></div>
                                    <div class="image-card-content px-5 py-4">
                                        <div class="image-card-description mb-0">
                                            <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}><b>zero waste.</b></h5>
                                            <h5 className='p-4 pb-0 pt-0 mt-0'><b>Bienenwachstücher</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div> */}

                        {
                            sellerdata?.data?.map((item) => (
                                <div className='p-2' key={item.id}>
                                    <Link to='https://shop.zogi-nature.de/' target='_blank'>
                                        <div className="img-card image-card-hover text-white rounded-0">
                                            <img src={item.imageUrl} alt="" className='' />
                                            <div className="image-card-overlay"></div>
                                            <div className="image-card-content px-5 py-4">
                                                <div className="image-card-description mb-0">
                                                    <h5 className='p-4 pb-0 mb-0' style={{ color: '#CBD62E' }}>
                                                        <b>{item.title}</b>
                                                    </h5>
                                                    <h5 className='p-4 pb-0 pt-0 mt-0'>
                                                        <b dangerouslySetInnerHTML={{ __html: item.description }}></b>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )) || <p>Loading seller data...</p>
                        }



                    </Carousel>
                    {/* <div className='py-5' style={{ padding: '0.5rem' }}>
                        <div className='container home-campains text-center py-4'>
                            <h2 className='pt-3' style={{ color: '#CBD62E' }}>{tHome("our_campains_section").title1}</h2>
                            <h2 className='pb-4'>{tHome("our_campains_section").title2}</h2>
                            <p className='text-white fs-5 p-5 pt-0 pb-0'>
                            {tHome("our_campains_section").content}
                            </p>
                            <div className="d-flex justify-content-center p-3">
                                <button className="btn rounded-0" style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}>
                                    <Link to="/campains" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark" aria-disabled="true">{tHome("our_campains_section").button}</Link>
                                </button>
                            </div>
                        </div>
                    </div> */}

                    <div className='py-5' style={{ padding: '0.5rem' }}>
                        <div className='container home-campains text-center py-4'>
                            <h2 className='pt-3' style={{ color: '#CBD62E' }}>{advdata?.data[0].title1}</h2>
                            <h2 className='pb-4'>{advdata?.data[0].title2}</h2>
                            <p className='text-white fs-5 p-5 pt-0 pb-0' dangerouslySetInnerHTML={{ __html: advdata?.data[0].description }}> 
                            </p>
                            <div className="d-flex justify-content-center p-3">
                                <button className="btn rounded-0" style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}>
                                    <Link to="/campains" className="nav-link fs-6 ps-4 pe-4 p-1 fw-bold text-dark" aria-disabled="true">{tHome("our_campains_section").button}</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
