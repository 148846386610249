import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";  
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    // we init with resources
    lng:"de",
    fallbackLng: "de",
    debug: true,
    returnObjects:true,
    // resources: {
    //   en: {
    //     translations: {
          
    //       "Welcome to React": "Welcome to React and react-i18next",
    //     }
    //   },
    //   de: {
    //     translations: {
         
    //       "Welcome to React": "Willkommen bei React und react-i18next"
    //     }
    //   }
    // },
   
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    },
    backend: {
      // loadPath: () => {
      //   // check the domain
      //   const host = window.location.host;
      //   return (host === 'production.ltd' ? '/static/app':'') + '/zogi_nature/locales/{{lng}}/{{ns}}.json';
      // }

      // loadPath:'/locales/{{lng}}/{{ns}}.json'
    }
  });

export default i18n;



