import React, { useState } from 'react'
import '../assets/css/ContactUs.css';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import HomeFooter from '../components/HomeFooter';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import maps from '../assets/images/mapSS.png'

export default function ContactUs() {
    const { t, i18n } = useTranslation('footer');
    const { t: tcontact, i18n: i18ncontact } = useTranslation('contact');


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Send user details to client email
        emailjs.send('service_k0j1y4j', 'template_tcw5ryh', formData, 'YMb7rFlFuYpPQUYjS')
            .then((result) => {
                console.log(result.text);
                // Send confirmation email to user
                // sendConfirmationEmail();
                // Display confirmation message to user
                if (result) {
                    // emailjs.send('service_t2s93mq', 'template_aj6m59w', {
                    //     to_email: formData?.email
                    // }, 'X3IIbNb7CBcV_bo9L');
                    alert('Your message was sent successfully!');
                }



                // Reset form fields
                setFormData({ name: '', email: '', company: '', message: '' });
            }, (error) => {
                console.log(error.text);
                alert('An error occurred. Please try again later.');
            });

    };

    // const sendConfirmationEmail = () => {
    //     // Send confirmation email to user

    //     emailjs.send('service_t2s93mq', 'template_aj6m59w', {
    //         to_email: formData?.email
    //     }, 'X3IIbNb7CBcV_bo9L');

    // };

    return (
        <>
            <Nav />
            <div className='contact-banner' style={{ height: '300px' }}>
                <div className='text-center text-white' style={{ paddingTop: '150px', position: 'relative', zIndex: 10 }}>
                    {/* <h1>IHR KONTAKT ZU UNS</h1> */}
                </div>
            </div>

            <div className='contact-side-color'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div class="col-12 col-md-6 col-sm-6 col-lg-6 text-white">
                            <div className='mt-4 pb-0 '>
                                {/* <h1>Get in <span style={{ color: '#CBD62E' }}>Touch</span></h1> */}
                                <h1>{tcontact("banner").title}</h1>
                                {/* <p className='fs-5'>Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus.</p> */}
                            </div>
                            {/* <form>
                                <input class="form-control m-4 p-3 cinput rounded-0" type="text" name="name" placeholder="Fullname*" />
                                <input class="form-control m-4 p-3 cinput rounded-0" type="email" name="email" placeholder="Email Id*" />
                                <textarea className='form-control m-4 p-3 cinput rounded-0' placeholder='Your Message'></textarea>

                                <div class="text-center">
                                    <button className='btn rounded-0 mt-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="#" className="nav-link fs-6 ps-3 pe-3 p-1 fw-bold text-dark" aria-disabled="true" >Send</Link></button>                               
                                 </div>
                            </form> */}

                            <div className='row justify-content-center mt-3'>
                                <div className='col-lg-12 '>
                                    {/* <form>
                                        <div className='mb-3'>
                                            <input type='text' id='nameInput' className='form-control rounded-0' placeholder={t("placeholders").name} />
                                        </div>
                                        <div className='mb-3'>
                                            <input type='email' id='emailInput' className='form-control rounded-0' placeholder={t("placeholders").email} />
                                        </div>
                                        <div className='mb-3'>
                                            <input type='text' id='companyInput' className='form-control rounded-0' placeholder={t("placeholders").company} />
                                        </div>
                                        <div className='mb-3'>
                                            <textarea className='form-control rounded-0' id='messageTextarea' rows='5' placeholder={t("placeholders").message}></textarea>
                                        </div>
                                        <div className='text-center'>

                                            <button className='btn rounded-0 mt-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}><Link to="#" className="nav-link fs-6 ps-3 pe-3 p-1 fw-bold text-dark" aria-disabled="true" >{t("menu").submit_btn}</Link></button>
                                        </div>
                                    </form> */}

                                    <form onSubmit={handleSubmit}>
                                        <div className='mb-3'>
                                            <input type='text' id='name' className='form-control rounded-0' value={formData.name} onChange={handleChange} placeholder={t("placeholders").name} />
                                        </div>
                                        <div className='mb-3'>
                                            <input type='email' id='email' className='form-control rounded-0' value={formData.email} onChange={handleChange} placeholder={t("placeholders").email} />
                                        </div>
                                        <div className='mb-3'>
                                            <input type='text' id='company' className='form-control rounded-0' value={formData.company} onChange={handleChange} placeholder={t("placeholders").company} />
                                        </div>
                                        <div className='mb-3'>
                                            <textarea className='form-control rounded-0' id='message' rows='5' value={formData.message} onChange={handleChange} placeholder={t("placeholders").message}></textarea>
                                        </div>
                                        <div className='text-center'>
                                            <button type="submit" className='btn rounded-0 mt-2' style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}>{t("menu").submit_btn}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className='col-12 col-md-6 col-sm-6 col-lg-6'>
                            <div className='p-3 m-2 map'>
                                {/* <iframe className='' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2621.905442278147!2d8.556617576467813!3d48.91719377134024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47970da8426b491f%3A0x71c5be70e2fea9b1!2sZOGI%20Europe%20GmbH!5e0!3m2!1sen!2sin!4v1709101750864!5m2!1sen!2sin" width="600px" height="450px" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">

                                </iframe> */}

                                <div style={{width:"600px", height:"450px"}}>
                                    <img src={maps} className='w-100' />
                                </div>


                                <div className='address' style={{ width: "600px", height: "100px", marginTop: '-105px', backgroundColor: '#1D1C1C', opacity: '0.8' }}>
                                    <div className='row p-3 text-white' >
                                        <div className='col-12 col-md col-sm col-lg ps-5'>
                                            <p>ZOGI NATURE GmbH <br />Gutenbergstraße 2<br />75210 Keltern</p>
                                        </div>
                                        <div className='col-12 col-md col-sm col-lg secol'>
                                            <p>T.: +49 7236 98286 - 0</p>
                                            <p>nature@zogi.biz</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
