import React from "react";
import { useState,useEffect } from "react";
import "../assets/css/SplashScreen.css";

import logo from '../assets/images/new_logo.png'
 
function SplashScreen() {
  return (
    <div className="zoom-in-zoom-out">
      <img src={logo} />
    </div>
  );
}

export default SplashScreen;
