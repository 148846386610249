import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from "../assets/images/new_logo.png";
import bannerbg from '../assets/images/banner-bg.png';
import '../assets/css/Nav.css';

import { HashLink } from 'react-router-hash-link';

import languageIconColored from '../assets/images/lng-icon-colored.png'
import languageIcon from '../assets/images/lng-icon.png'

import { layoutLang } from '../assets/languages/LayoutLang';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../redux/reducers/LanguageSlice';
import { useTranslation } from 'react-i18next';

export default function Nav() {
    const languageState = useSelector((state) => state.language)
    const [isEnglish, setIsEnglish] = useState(languageState.isEnglish);
    const location = useLocation();
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation('header');
    const [lang, setLang] = useState(i18n.language === 'de');
    const [data, setData] = useState(null)


    const currentLanguage = i18n.language;


    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        function toggleNavbarBackground() {
            if (window.scrollY > 0) {
                navbar.classList.add('bg-blackColor');
            } else {
                navbar.classList.remove('bg-blackColor');
            }
        }
        window.addEventListener('scroll', toggleNavbarBackground);
        return () => {
            window.removeEventListener('scroll', toggleNavbarBackground);
        };
    }, []);


    const changeLanguageHandler = lng => {
        i18n.changeLanguage(lng);
        setLang(lng === "de");
    };

    const isActive = (path) => {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
            navbarCollapse.classList.remove('show');
        }
        // console.log(path);
        // alert(path)
        return location.pathname === path ? 'active' : '';
    };

    const isActiveHash = (hash) => {
        const navbarCollapse = document.querySelector('.navbar-collapse');
        if (navbarCollapse) {
            navbarCollapse.classList.remove('show');
        }
        return window.location.hash === hash ? 'active' : '';
    };

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    // Function to detect mobile devices
    function isMobileDevice() {
        return /Mobi|Android/i.test(navigator.userAgent);
    }


    const handleMicroFactoriesClick = (event) => {
        if (isMobileDevice()) {
            // Toggle dropdown menu if on a mobile device
            setIsDropdownOpen(!isDropdownOpen);
            // Prevent event propagation to avoid closing navbar collapse
            event.stopPropagation();
            event.preventDefault();
        } else {
            // Close the navbar collapse if it's open
            const navbarCollapse = document.querySelector('.navbar-collapse');
            if (navbarCollapse && navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
            }
            // Redirect to the other page for non-mobile devices
            window.location.href = "/microfactories";
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/manufacturer/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [currentLanguage])


    return (

        <>
            <nav className="navbar top-nav navbar-expand-lg fixed-top">
                <div className="container">
                    <Link to="/" className='logo p-0 m-0' ><img src={logo} style={{ width: '85%' }} className="navbar-brand logo pt-2" href="#" alt="Logo" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className={`nav-item ${(isActive('/about')) ? 'active' : ''}`}>
                                <Link to="/about" className={`nav-link fs-6 text-white ${lang ? 'deMenu' : 'p-4'}`}><b>{t("menu").about}</b></Link>
                            </li>
                            <li className={`nav-item dropdown ${(isActive('/microfactories')) ? 'active' : ''}`} >
                                <Link to="#" className={`nav-link text-white fs-6 dropdown-toggle ${lang ? ' dropdown-toggle deMenu' : 'p-4'}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <b>{t("menu").micro_factories}</b>
                                </Link>
                                {/* <Link to="#" className={`nav-link text-white dropdown-toggle fs-6 ${lang ? 'p-0 deMenu' : 'p-4'}`} role="button" onClick={(event) => handleMicroFactoriesClick(event)}>
                                    <b>{t("menu").micro_factories}</b>
                                </Link> */}
                                <ul className={`dropdown-menu p-3 ${isDropdownOpen ? 'show' : ''}`} >
                                    {/* <li><HashLink to="/microfactories#beeslove" className={`dropdown-item p-2 ${isActiveHash('#beeslove')}`} smooth>{t("menu").service1}</HashLink></li>
                                    <li><HashLink to="/microfactories#seeds" className={`dropdown-item p-2 ${isActiveHash('#seeds')}`} smooth>{t("menu").service2}</HashLink></li>
                                    <li><HashLink to="/microfactories#agriculture" className={`dropdown-item p-2 ${isActiveHash('#agriculture')}`} smooth>{t("menu").service3}</HashLink></li>
                                    <li><HashLink to="/microfactories#genusslab" className={`dropdown-item p-2 ${isActiveHash('#genusslab')}`} smooth>{t("menu").service4}</HashLink></li>
                                    <li><HashLink to="/microfactories#carpetary" className={`dropdown-item p-2 ${isActiveHash('#carpetary')}`} smooth>{t("menu").service5}</HashLink></li>
                                    <li><HashLink to="/microfactories#creation" className={`dropdown-item p-2 ${isActiveHash('#creation')}`} smooth>{t("menu").service6}</HashLink></li> */}
                                    {
                                        data?.data?.map((item) => (
                                            <HashLink to={`/microfactories#${item.slug}`} className={`dropdown-item p-2 ${isActiveHash(`#${item.title}`)}`} smooth>
                                                {item.title}
                                            </HashLink>
                                        ))
                                    }
                                </ul>

                            </li>
                            <li className={`nav-item dropdown ${(isActive('/special_events') || isActive('/campains')) ? 'active' : ''}`}>
                                <Link to="#" className={`nav-link text-white fs-6 dropdown-toggle ${lang ? 'dropdown-toggle deMenu' : 'p-4 pt-4 enmenuMob'}`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <b>{t("menu").services}</b>
                                </Link>
                                <ul className="dropdown-menu p-3">
                                    <li><Link to="/campains" className={`dropdown-item p-2 ${(isActive('/campains')) ? 'active' : ''}`}>{t("menu").campaign1}</Link></li>
                                    <li><Link to="/special_events" className={`dropdown-item p-2 ${(isActive('/special_events')) ? 'active' : ''}`}>{t("menu").campaign2}</Link></li>

                                </ul>
                            </li>
                            {/* <li className={`nav-item ${(isActive('/partners')) ? 'active' : ''}`}>
                                <Link to="/partners" className={`nav-link fs-6 ${lang ? 'p-0 deMenu' : 'p-2 pt-4 enmenuMob'} text-white`}><b>{t("menu").zogi_natures_partner}</b></Link>
                            </li> */}
                            <li className={`nav-item ${(isActive('/partners')) ? 'active' : ''}`}>
                                <Link to="/partners" className={`nav-link fs-6 ${lang ? ' deMenu' : 'p-4 pt-4 enmenuMob'} text-white`}><b>{t("menu").zogi_natures_partner}</b></Link>
                            </li>
                            <li className={`nav-item ${(isActive('/contact')) ? 'active' : ''}`}>
                                <Link to="/contact" className={`nav-link fs-6 ${lang ? ' deMenu' : 'p-4'} text-white`}><b>{t("menu").contact}</b></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="https://shop.zogi-nature.de" target='_blank' className="nav-link fs-6 ps-4 pe-4 mt-3 fw-bold text-dark" aria-disabled="true" style={{ backgroundColor: '#CBD62E', whiteSpace: 'nowrap' }}>Shop Now</Link>
                            </li>
                            <li className="nav-item">
                                <button className={`btn btn-outline dropdown-toggle nav-link fs-6 ms-1 mt-3 rounded-3`} aria-disabled="true" style={{ color: lang ? '#CBD62E' : '#CBD62E' }} onClick={() => changeLanguageHandler(lang ? "en" : "de")}><b className='ps-4 mt-3'>{lang ? 'EN' : 'DE'}</b></button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}
