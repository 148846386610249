import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "languageReducer",
  initialState: {
    isEnglish: localStorage.getItem("isEnglish")
    ? localStorage.getItem("isEnglish")
    :false,
    language: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "gr"
  },
  reducers: {
    changeLanguage: (state, action) => {
      localStorage.setItem("language", action.payload.language);
      localStorage.setItem("isEnglish", action.payload.isEnglish);
      console.log(action.payload);
      state.language = action.payload.language;
      state.isEnglish = action.payload.isEnglish
    },
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(getUser.fulfilled, (state, action) => {
//         state.status = "Succceded";
//         state.users = action.payload;
//       })
//       .addCase(openModelAction, (state, action) => {
//         state.modelOpen = true;
//       })
//       .addCase(getTodos.fulfilled, (state, action) => {
//         state.todos = action.payload;
//       });
//   },
});

export default languageSlice.reducer;
export const { changeLanguage } = languageSlice.actions;
