import React, { useEffect, useState } from 'react'
import AliceCarousel from 'react-alice-carousel';
import client1 from '../assets/images/breuning.svg'
import client2 from '../assets/images/Ivoclar.svg'
import client3 from '../assets/images/ksc_client.svg'
import client4 from '../assets/images/rewe_client.svg'
import client5 from '../assets/images/ytong_client.svg'


import 'react-alice-carousel/lib/alice-carousel.css';



export default function OurClients() {

    const [clientdata, setClientdata] = useState([])
    useEffect(() => {

        const fetchAdvertiseData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/partners/list`, {
                });
                const result = await response.json();
                setClientdata(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAdvertiseData();

    }, [])

    console.log('data', clientdata)


    const logoResponsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 7 },
    };


    // const logo = [
    //     <div className="client-logo">
    //         <img src={client1} alt="Our Investors" className='' />
    //     </div>,
    //     <div className="client-logo">
    //         <img src={client2} alt="Our Investors" className='' />
    //     </div>,
    //     <div className="client-logo">
    //         <img src={client3} alt="Our Investors" className='' />
    //     </div>,
    //     <div className="client-logo">
    //         <img src={client4} alt="Our Investors" className='' />
    //     </div>,
    //     <div className="client-logo">
    //         <img src={client5} alt="Our Investors" className='' />
    //     </div>,
    // ];

    const logo = clientdata?.data?.map((client, index) => (
        <div className="client-logo" key={index}>
            <img src={client.logo} alt={client.name || "Our Partner"} className='' />
        </div>
    ));

    return (
        <>
            <div className='container'>
            <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <h2 className='text-center pb-3' style={{ color: '#CBD62E' }}>PARTNER</h2>
                <AliceCarousel
                    mouseTracking
                    items={logo}
                    responsive={logoResponsive}
                    paddingLeft="10"
                    disableDotsControls="false"
                    infinite="true"
                    autoPlay="true"
                    disableButtonsControls="false"
                    autoPlayStrategy="none"
                    autoPlayInterval="2000"
                    animationType="slide"
                    touchMoveDefaultEvents="true"
                />
            </div>
        </>
    )
}
