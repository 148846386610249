import React, { useEffect, useRef, useState } from 'react';
import '../assets/css/Microfactories.css';
import microfactbanner2 from '../assets/images/manufacture-banner.png';
import microfactcar1 from '../assets/images/microfact-car1.png';
import { Link, useLocation } from 'react-router-dom';
// import Carpentry from '../assets/pdf/Carpentry.pdf';
// import Beeswe from '../assets/pdf/Beeswe.pdf';
// import spices from '../assets/pdf/spices.pdf';
// import seeds from '../assets/pdf/Agriculture.pdf'
// import creation from '../assets/pdf/GreenBusiness.pdf'

import carpetarycarousal1 from '../assets/images/carpetarycarousal1.png'
import CarpetaryCarousal2 from '../assets/images/carpetarycarousal2.png'
import CarpetaryCarousal3 from '../assets/images/carpetarycarousal3.png'

import BeesCarousal1 from '../assets/images/BeesCarousal1.png'
import BeesCarousal2 from '../assets/images/BeesCarousal2.png'
import BeesCarousal3 from '../assets/images/BeesCarousal3.png'
import BeesCarousal4 from '../assets/images/BeesCarousal4.png'

import SeedsCarousal1 from '../assets/images/SeedsCarousal1.png'
import SeedsCarousal2 from '../assets/images/SeedsCarousal2.png'
import SeedsCarousal3 from '../assets/images/SeedsCarousal3.png'
import SeedsCarousal4 from '../assets/images/SeedsCarousal4.png'
import SeedsCarousal5 from '../assets/images/SeedsCarousal5.png'


import SpicesCarousal1 from '../assets/images/SpicesCarousal1.png'
import SpicesCarousal2 from '../assets/images/SpicesCarousal2.png'
import SpicesCarousal3 from '../assets/images/SpicesCarousal3.png'

import CreationCarousal1 from '../assets/images/CreationCarousal1.png'
import CreationCarousal2 from '../assets/images/CreationCarousal2.png'
import CreationCarousal3 from '../assets/images/CreationCarousal3.png'
import CreationCarousal4 from '../assets/images/CreationCarousal4.png'

// v2
import carpentry from '../assets/images/carpentry.jpg'
import beeswe from '../assets/images/besswe.png'
import seed from '../assets/images/seeds.png'
import speices from '../assets/images/Spiceseasoning.png'
import kreation from '../assets/images/Kriation.png'
import landwirtschaft_manufacture from '../assets/images/landwirtschaft_manufacture.png'
import genesslab_manufacture from '../assets/images/genesslab_manufacture.png'
import creation_manufacture from '../assets/images/creation_manufacture.png'
import gennus from '../assets/images/genuss.png'
import seeds_logo from '../assets/images/seeds_logo.png'
import agriculture from '../assets/images/agriculture.png'
import bee_logo from '../assets/images/bee.png'
import carpentary_logo from '../assets/images/carpentry-logo.png'
import creation_logo from '../assets/images/creation.png'
import Carousel from 'react-multi-carousel';
import stamp_logo from '../assets/images/stamp_logo.png'
import manufactCarousal1 from '../assets/images/manufactCarousal1.jpg'
import manufactCarousal2 from '../assets/images/manufactCarousal2.jpg'
import manufactCarousal3 from '../assets/images/manufactCarousal3.jpg'
import manufactCarousal4 from '../assets/images/manufactCarousal4.jpg'
import manufactCarousal5 from '../assets/images/manufactCarousal5.jpg'
import manufactCarousal6 from '../assets/images/manufactCarousal6.jpg'
import { useTranslation } from 'react-i18next';


export default function Microfactories() {
    const { t: tmanufacture, i18n: i18nmanufacture } = useTranslation('manufacture');
    const [data, setData] = useState(null)


    const currentLanguage = i18nmanufacture.language;



    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    // useEffect(() => {
    //     const scrollToElement = () => {
    //         if (window.location.hash) {
    //             const elementId = window.location.hash.substring(1);
    //             const element = document.getElementById(elementId);
    //             console.log('element',elementId)
    //             if (element) {
    //                 const headerOffset = 150; // Adjust this value according to your header's height
    //                 const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    //                 const offsetPosition = elementPosition - headerOffset;

    //                 window.scrollTo({
    //                     top: offsetPosition,
    //                     behavior: 'smooth'
    //                 });
    //             }
    //         }
    //     };

    //     // Handle initial load
    //     scrollToElement();

    //     // Handle hash changes
    //     window.addEventListener('hashchange', scrollToElement);

    //     // Clean up the event listener
    //     return () => {
    //         window.removeEventListener('hashchange', scrollToElement);
    //     };
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/manufacturer/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [currentLanguage])


    return (
        <>
            {/* banner section */}
            {/* <div className='HeaderBanner_img'>
                <img src={microfactbanner2} alt='...' className='w-100' />
            </div> */}

            {/* banner carousal */}
            <div className='HeaderBanner_img' style={{ position: 'relative' }} >
                <Carousel className='manufact_carousal' responsive={responsive} infinite={true} autoPlay={true} showDots={true} autoPlaySpeed={2000} pauseOnHover={false}

                >
                    <div className=''>
                        <img src={manufactCarousal1} alt="" className='w-100 opacity-50' />
                    </div>
                    <div className=''>
                        <img src={manufactCarousal2} alt="" className='w-100 opacity-50' />
                    </div>
                    <div className=''>
                        <img src={manufactCarousal3} alt="" className='w-100 opacity-50' />
                    </div>
                    <div className=''>
                        <img src={manufactCarousal4} alt="" className='w-100 opacity-50' />
                    </div>
                    <div className=''>
                        <img src={manufactCarousal5} alt="" className='w-100 opacity-50' />
                    </div>
                    <div className=''>
                        <img src={manufactCarousal6} alt="" className='w-100 opacity-50' />
                    </div>
                </Carousel>
                <div className="static-text text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '999' }}>
                    <img src={stamp_logo} alt='...' className='' style={{ width: '50%' }} />
                </div>
            </div>

            <div>
                <div className='container-fluid text-white pt-2'>
                    <div className='text-center pt-5 pb-4 '>
                        <h2 className='fh' style={{ color: '#CBD62E' }}>{tmanufacture("banner").title1}</h2>
                        <h2>{tmanufacture("banner").title2}</h2>
                    </div>
                    <div className='container  text-white' style={{}}>
                        <div className='text-center pb-1'>
                            <p className='fs-5' >
                                {tmanufacture("banner").content1}
                            </p>
                            <p className='fs-5'>
                                {tmanufacture("banner").content2}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap justify-content-center py-5 pb-0 pt-1">
                        <img src={gennus} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                        <img src={seeds_logo} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                        <img src={agriculture} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                        <img src={bee_logo} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                        <img src={carpentary_logo} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                        <img src={creation_logo} class="flex-img" alt='....' className='p-3' style={{ width: '58px', height: '60px' }} />
                    </div>
                </div>
            </div>

            <div className='container text-white'>

                {
                    data?.data?.map((item, index) => (

                        <>
                            {index % 2 === 0 ? (
                                <div className='d-flex align-items-center manufact_m_pad' id={`${item.slug}`}>
                                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                                            <img src={item.imageUrl} class="d-block w-100" alt="..." />
                                        </div>
                                        <div className='col'>
                                            <div className="mt-2 ps-5 pt-4 fp">
                                                <h2 className=''>{item.title}</h2>
                                                <p className='text-justify fw-normal mt-3 fs-5' dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='d-flex align-items-center manufact_m_pad' id={`${item.slug}`}>
                                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                                        <div className='col order-sm-2 order-lg-1 order-md-1'>
                                            <div className="mt-2 p-5 ps-0  fp">
                                                <h2 className=''>{item.title}</h2>
                                                <p className='text-justify fw-normal mt-3 fs-5'dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                            </div>
                                        </div>
                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 order-sm-1 order-lg-2 order-md-2'>
                                            <img src={item.imageUrl} class="d-block w-100" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>

                    ))
                }

                {/* <div className=' manufact_m_pad'  >
                    <div className='row w-100'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={beeswe} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col'>
                            <div className="mt-2 ps-3 fp">
                                <h2 className=''>{tmanufacture("beeswe_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("beeswe_section").content1}</p>
                                <p className='text-justify fw-normal mt-3 fs-5'>{tmanufacture("beeswe_section").content2}</p>
                                <a href='https://beeswe.love/' target="_blank" rel="noopener" className='text-decoration-none mt-3 rounded-0 fs-5' style={{ color: '#CBD62E', borderBottom: '1px solid #CBD62E' }}>beeswe.love</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='seeds'>
                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                        <div className='col order-2 order-sm-2 order-lg-1 order-md-1'>
                            <div className="mt-2 p-5 ps-0  fp">
                                <h2 className=''>{tmanufacture("seeds_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("seeds_section").content}</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 order-sm-1 order-lg-2 order-md-2'>
                            <img src={seed} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='agriculture'>
                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={landwirtschaft_manufacture} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col'>
                            <div className="mt-2 ps-5 pt-4 fp">
                                <h2 className=''>{tmanufacture("agriculture_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("agriculture_section").content}</p>
                                <Link to="/campains" className='text-decoration-none mt-3 rounded-0 fs-5' style={{ color: '#CBD62E', borderBottom: '1px solid #CBD62E' }}> {tmanufacture("agriculture_section").link}</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='genusslab'>
                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                        <div className='col order-sm-2 order-lg-1 order-md-1'>
                            <div className="mt-2 p-5 ps-0  fp">
                                <h2 className=''>{tmanufacture("genuss_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("genuss_section").content}</p>
                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 order-sm-1 order-lg-2 order-md-2'>
                            <img src={genesslab_manufacture} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='carpetary' >
                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={carpentry} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col'>
                            <div className="mt-2 p-3 fp">
                                <h2 className=''>{tmanufacture("carpentry_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("carpentry_section").content}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center manufact_m_pad' id='creation'>
                    <div className='row w-100 hashpadding' style={{ paddingTop: '170px' }}>
                        <div className='col order-sm-2 order-lg-1 order-md-1'>
                            <div className="mt-2 p-5 ps-0  fp">
                                <h2 className=''>{tmanufacture("creation_section").title}</h2>
                                <p className='text-justify fw-normal mt-3 fs-5' >{tmanufacture("creation_section").content}</p>

                            </div>
                        </div>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 order-sm-1 order-lg-2 order-md-2'>
                            <img src={creation_manufacture} class="d-block w-100" alt="..." />
                        </div>
                    </div>
                </div> */}

            </div>
        </>
    )
}
