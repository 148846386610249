import React from 'react'
import Mission from '../components/Mission'
import bannerCardImg from '../assets/images/banner-card-img.png'
import '../assets/css/About.css'
import aboutBanner from '../assets/images/about_banner.png'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";

import abtcarousal1 from '../assets/images/abt-carousal1.png'
import abtcarousal2 from '../assets/images/abt-carousal2.png'
import abtcarousal3 from '../assets/images/abt-carousal3.png'

import testimonial1 from '../assets/images/testimonial1.png';

import YTONGcarousal1 from '../assets/images/abt-modal-car1.png'
import YTONGcarousal2 from '../assets/images/YTONGcarousal2.png'
import YTONGcarousal3 from '../assets/images/YTONGcarousal3.png'
import YTONGcarousal4 from '../assets/images/YTONGcarousal4.png'
import YTONGcarousal5 from '../assets/images/YTONGcarousal5.png'
import YTONGcarousal6 from '../assets/images/YTONGcarousal6.png'


import REWEcarousal1 from '../assets/images/REWEcarousal1.png'
import REWEcarousal2 from '../assets/images/REWEcarousal2.png'
import REWEcarousal3 from '../assets/images/REWEcarousal3.png'
import REWEcarousal4 from '../assets/images/REWEcarousal4.png'
import REWEcarousal5 from '../assets/images/REWEcarousal5.png'
import REWEcarousal6 from '../assets/images/REWEcarousal6.png'


import KSCcarousal1 from '../assets/images/KSCcarousal1.png'
import KSCcarousal2 from '../assets/images/KSCcarousal2.png'
import KSCcarousal3 from '../assets/images/KSCcarousal3.png'
import KSCcarousal4 from '../assets/images/KSCcarousal4.png'


import abtCarLogo1 from '../assets/images/abt-car-logo1.png'
import abtCarLogo2 from '../assets/images/abt-car-logo2.png'
import abtCarLogo3 from '../assets/images/abt-car-logo3.png'

import Quotes from '../assets/images/quotes.png';

import { NavLink } from 'react-router-dom';
import ytong from '../assets/images/ytong_aboutus.png'
import beeswe from '../assets/images/beeswe-aboutus.png'
import ksc from '../assets/images/ksc-aboutus.png'
import rewe from '../assets/images/rewe-aboutus.png'

import BannerBg from '../assets/images/aboutBannerBg.png'
import successstory_ytong_carousal1 from '../assets/images/successstory_ytong_carousal1.png'
import successstory_ytong_carousal2 from '../assets/images/successstory_ytong_carousal2.png'
import successstory_ytong_carousal3 from '../assets/images/successstory_ytong_carousal3.png'
import successstory_ytong_carousal4 from '../assets/images/successstory_ytong_carousal4.png'
import successstory_ytong_carousal5 from '../assets/images/successstory_ytong_carousal5.png'
import successstory_ytong_carousal6 from '../assets/images/successstory_ytong_carousal6.png'
import successstory_rewe_carousal1 from '../assets/images/successstory_rewe_carousal1.png'
import successstory_rewe_carousal2 from '../assets/images/successstory_rewe_carousal2.png'
import successstory_rewe_carousal3 from '../assets/images/successstory_rewe_carousal3.png'
import successstory_rewe_carousal4 from '../assets/images/successstory_rewe_carousal4.png'
import successstory_rewe_carousal5 from '../assets/images/successstory_rewe_carousal5.png'
import successstory_rewe_carousal6 from '../assets/images/successstory_rewe_carousal6.png'
import successstory_ksc_carousal1 from '../assets/images/successstory_ksc_carousal1.png'
import successstory_ksc_carousal2 from '../assets/images/successstory_ksc_carousal2.png'
import successstory_ksc_carousal3 from '../assets/images/successstory_ksc_carousal3.png'
import successstory_ksc_carousal4 from '../assets/images/successstory_ksc_carousal4.png'

import legal from '../assets/images/legal.png'
import glob from '../assets/images/globe.png'
import speech from '../assets/images/speech.png'
import thumb from '../assets/images/thumb.png'
import tie from '../assets/images/tie.png'
import money from '../assets/images/money.png'
import { useTranslation } from 'react-i18next'


export default function About() {


    const { t: tabout, i18n: i18nabout } = useTranslation('about');


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsive2 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>


            {/* banner section */}
            <div className='HeaderBanner_img' >
                <img src={BannerBg} alt='...' className='w-100' style={{ height: '100%' }} />
            </div>

            {/* Our Mission Section */}
            <div>
                <div className='container-fluid '>
                    <h2 className='text-center pt-5' style={{ color: '#CBD62E' }}>{tabout("mission_section").title}</h2>
                    <div className='container about_mission'>
                        <div className='text-center text-white pb-5'>
                            <p className='fw-normal fs-5' style={{ lineHeight: 2 }}>
                                {tabout("mission_section").content1}
                            </p>
                            <p className='fw-normal fs-5' style={{ lineHeight: 2 }}>
                                {tabout("mission_section").content2}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Nachhaltiges Marketing */}
            <div className='container Nachhaltiges_marketing'>
                <h2 className='text-center pt-5' style={{ color: '#CBD62E' }}>{tabout("marketting_card_section").title1}</h2>
                <h2 className='text-center text-white'>{tabout("marketting_card_section").title2}</h2>

                <div className='row text-white p-5'>
                    <div className='col-md-4 p-3'>
                        <img src={legal} alt='...' className='pb-2' style={{ width: '40px' }} />
                        <h4>{tabout("marketting_card_section").legal_framework_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").legal_framework_content}</p>
                    </div>
                    <div className='col-md-4 p-3'>
                        <img src={glob} alt='...' className='pb-2' style={{ width: '25px' }} />
                        <h4>{tabout("marketting_card_section").concrete_effects_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").concrete_effects_content}
                        </p>
                    </div>
                    <div className='col-md-4 p-3'>
                        <img src={speech} alt='...' className='pb-0' style={{ width: '27px' }} />
                        <h4>{tabout("marketting_card_section").consistent_communication_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").consistent_communication_content}
                        </p>
                    </div>
                    <div className='col-md-4 p-3'>
                        <img src={thumb} alt='...' className='pb-2' style={{ width: '35px' }} />
                        <h4>{tabout("marketting_card_section").social_acceptance_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").social_acceptance_content}</p>
                    </div>
                    <div className='col-md-4 p-3'>
                        <img src={tie} alt='...' className='pb-2' style={{ width: '16px' }} />
                        <h4>{tabout("marketting_card_section").credible_appearance_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").credible_appearance_content}</p>
                    </div>
                    <div className='col-md-4 p-3'>
                        <img src={money} alt='...' className='pb-2' style={{ width: '30px' }} />
                        <h4>{tabout("marketting_card_section").economic_perspective_title}</h4>
                        <p className='fw-normal' style={{ fontSize: '17px' }}>{tabout("marketting_card_section").economic_perspective_content}
                        </p>
                    </div>
                    <p className='fw-normal text-center' style={{ fontSize: '17px' }}>Icons by <a href='https://icons8.com/' target='_blank' className=' text-white'>icons8</a></p>
                </div>
            </div>

            {/* success story */}
            <div className='container py-5 mt-2' style={{ width: '100%' }}>
                <hr style={{ border: '2px solid #CBD62E', width: '8%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <h2 className='text-center pb-4' style={{ color: '#CBD62E' }}>{tabout("success_stories_section").title}</h2>

                <div className='py-3 w-75 mx-auto m_width' id='YTONG'>
                    <div id="carouselExampleControls_YTONG" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner w-75 mx-auto m_width">
                            <div class="carousel-item active">
                                <img src={successstory_ytong_carousal3} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ytong_carousal1} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ytong_carousal2} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ytong_carousal4} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ytong_carousal5} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ytong_carousal6} class="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button class="carousel-control-prev opacity-100" type="button" data-bs-target="#carouselExampleControls_YTONG" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next opacity-100" type="button" data-bs-target="#carouselExampleControls_YTONG" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>

                    </div>
                    <div className='text-white w-75 mx-auto pt-2 text-center m_width'>
                        <h4 className='p-2 pb-0 w-100 '>{tabout("success_stories_section").YTONG_title}</h4>
                        <hr style={{ border: '2px solid #CBD62E', width: '40%', zIndex: 100, opacity: 1 }} className='mx-auto' />
                        <p className='p-2 pb-0' style={{ fontSize: '16px' }}>{tabout("success_stories_section").YTONG_content}
                        </p>
                    </div>
                </div>

                <div className='py-3 w-75 mx-auto m_width' id='REWE'>
                    <div id="carouselExampleControls_REWE" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner w-75 mx-auto m_width">
                            <div class="carousel-item active">
                                <img src={successstory_rewe_carousal1} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_rewe_carousal2} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_rewe_carousal3} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_rewe_carousal4} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_rewe_carousal5} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_rewe_carousal6} class="d-block w-100" alt="..." />
                            </div>
                        </div>
                        <button class="carousel-control-prev opacity-100" type="button" data-bs-target="#carouselExampleControls_REWE" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next opacity-100" type="button" data-bs-target="#carouselExampleControls_REWE" data-bs-slide="next">
                            <span class="carousel-control-next-icon " aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>

                    </div>
                    <div className='text-white w-75 mx-auto pt-2 text-center m_width'>
                        <h4 className='p-2 pb-0'>{tabout("success_stories_section").REWE_title}</h4>
                        <hr style={{ border: '2px solid #CBD62E', width: '40%', zIndex: 100, opacity: 1 }} className='mx-auto' />
                        <p className='p-2 pb-0' style={{ fontSize: '16px' }}>{tabout("success_stories_section").REWE_content}
                        </p>
                    </div>
                </div>

                <div className='py-3 w-75 mx-auto m_width' id='KSC'>
                    <div id="carouselExampleControls_KSC" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner w-75 mx-auto m_width">
                            <div class="carousel-item active">
                                <img src={successstory_ksc_carousal1} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ksc_carousal2} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ksc_carousal3} class="d-block w-100" alt="..." />
                            </div>
                            <div class="carousel-item">
                                <img src={successstory_ksc_carousal4} class="d-block w-100" alt="..." />
                            </div>

                        </div>
                        <button class="carousel-control-prev opacity-100" type="button" data-bs-target="#carouselExampleControls_KSC" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon " aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next opacity-100" type="button" data-bs-target="#carouselExampleControls_KSC" data-bs-slide="next">
                            <span class="carousel-control-next-icon " aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>

                    </div>
                    <div className='text-white w-75 mx-auto pt-2 text-center m_width'>
                        <h4 className='p-2 pb-0'>{tabout("success_stories_section").KSC_title}</h4>
                        <hr style={{ border: '2px solid #CBD62E', width: '40%', zIndex: 100, opacity: 1 }} className='mx-auto' />
                        <p className='p-2 pb-0' style={{ fontSize: '16px' }}>{tabout("success_stories_section").KSC_content}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
