import logo from './logo.svg';
import './App.css';
import Nav from './components/Nav';
import Home from './pages/Home';
import Mission from './components/Mission';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Updated import statement
import About from './pages/About';
import Footer from './components/HomeFooter';
import Microfactories from './pages/Microfactories';
import ContactUs from './pages/ContactUs';
import SpecialEvents from './pages/SpecialEvents';
import Testpage from './pages/Testpage';
import Campains from './pages/Campains';
import PaegeRoutes from './components/Routes';
import ScrollToTop from './components/ScrollToTop';
import { useSelector } from 'react-redux';
import { layoutLang } from './assets/languages/LayoutLang';
import SplashScreen from './components/SplashScreen';
import { useEffect, useState } from 'react';
// import CookieConsent from 'react-cookie-consent';


function App() {

  const languageState = useSelector((state) => state.language)

  layoutLang.setLanguage(languageState.language);

  // console.log(languageState);

  const [loading, setLoading] = useState(true);
  const [scrollTop, setScrollTop] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, []);


  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        setScrollTop(true)
      } else {
        setScrollTop(false)
      }
    })
  }, [])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  return (
    <>
      {/* {loading ? (
        <SplashScreen />
      ) : ( */}
      <Router className="overlay-content">
        <ScrollToTop />
        <Routes>
          <Route path='/*' element={<PaegeRoutes />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>

      </Router>

      {/* <CookieConsent
        className="container"
        overlay={true}
        enableDeclineButton
        location="bottom"
        buttonText="akzeptiere"
        declineButtonText="Ablehnen"
        cookieName="ZogiCookie"
        style={{ background: "#313D44", padding: '10px' ,alignItems:'center'}}
        buttonStyle={{ color: "black", fontSize: "18px", backgroundColor:'#CBD62E ', paddingLeft:'1.5rem', paddingRight:'1.5rem'}}
        declineButtonStyle={{ color: "black", fontSize: "18px", backgroundColor:'#EC7063', paddingLeft:'1.5rem', paddingRight:'1.5rem'}}
        expires={150}
        cookieValue={true}
      >
        <h3>Privatsphäre-Einstellungen</h3>
        <p>Diese Website verwendet zustimmungspflichtige cookies und Technologien von Drittanbietern, um bestimmte Funktionen zu integrieren. Wenn Sie auf die Schaltfläche „Alle akzeptieren“ klicken, werden diese Funktionen aktiviert (Zustimmung). Nach erfolgter Einwilligung verarbeiten wir und die beteiligten Drittunternehmen Ihre personenbezogenen Daten für verschiedene Zwecke. Detaillierte Informationen zu Zweck, Rechtsgrundlage und Drittunternehmen finden Sie unter dem Button „Mehr“ und in unserer Datenschutzerklärung. Sie können Ihre Einwilligung jederzeit widerrufen.</p>
      </CookieConsent> */}

      {/* )} */}
      {
        (scrollTop && (
          <button className='btn btn-lg border border-dark scrolltopbtn' onClick={() => scrollToTop()}>
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={15}><path fill='balck' d="M241 130.5l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9l-22.7 22.7c-9.4 9.4-24.5 9.4-33.9 0L224 227.5 69.3 381.5c-9.4 9.3-24.5 9.3-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.6 0-33.9L207 130.5c9.4-9.4 24.6-9.4 33.9 0z"/></svg>
          </button>

        ))
      }
    </>
  );
}

export default App;
