import React, { useEffect, useState } from 'react';
import '../assets/css/Partners.css';
import partnerlogo1 from '../assets/images/client1.png'
import basf from '../assets/images/basf.png';
import piston from '../assets/images/piston.png';
import ksc from '../assets/images/ksc.png';
import rewe from '../assets/images/rewe.png';
import stellen from '../assets/images/stellen.png';
import thost from '../assets/images/thost.png';
import ytong from '../assets/images/ytong.png';

import admix from '../assets/images/admix.png';
import aled_logo_web from '../assets/images/aled-logo-web.svg'
import handin from '../assets/images/handin.png';
import barbers from '../assets/images/barbers.svg.png';
import baouer1 from '../assets/images/bauer-boecker1.svg.png';
import baouer2 from '../assets/images/bauer_walser.svg.png';
import bfss from '../assets/images/bfss.png';
import hoan from '../assets/images/hoan.png';
import bmk from '../assets/images/bmk.svg.png';
import brauhaus from '../assets/images/brauhaus.svg.png';
import breuning from '../assets/images/breuning.svg.png';
import bb from '../assets/images/bb.svg.png';
import casualfood from '../assets/images/casualfood.svg.png';
import cnw from '../assets/images/cnw.svg.png';
import crimex from '../assets/images/crimex.png';
import duetche from '../assets/images/duetche.png';
import digiplywebpate from '../assets/images/digiply-web-pate.svg.png';
import dkpromotion from '../assets/images/dkpromotion-logo-web-paten.svg.png';
import dynamicplus from '../assets/images/dynamik-plus-web-paten.svg.png';
import enayati from '../assets/images/enayati_logo-web-paten.svg.png';
import einzigartig from '../assets/images/einzigartig.svg.png';
import edshcut from '../assets/images/edshcut.png';
import die6 from '../assets/images/die6.svg.png';
import digitalsuedwest from '../assets/images/logo_digital_suedwest_web-pate.svg.png';
import eidx from '../assets/images/eidex_logo_web-pate.svg.png';
import fazh from '../assets/images/fazh.svg.png';
import flyeralrm from '../assets/images/flyeralrm.png';
import gavd from '../assets/images/gavd.png';
import gww from '../assets/images/gww.svg.png';
import glueksbringer from '../assets/images/gluecksbringer-catering_v2-web-paten.svg.png';
import gopi from '../assets/images/gopi.png'
import gtue from '../assets/images/gtue.svg.png'
import gvp from '../assets/images/gvp_logo_web-pate.svg.png'
import haas from '../assets/images/haas-holding.svg fill.png'
import ivoclar from '../assets/images/ivoclar_black_solid.svg.png'
import jakobs from '../assets/images/jakobs.svg.png'
import klaber from '../assets/images/klaiber.png'
import kober from '../assets/images/korber.png'
import kolibri from '../assets/images/kolibri.svg.png'
import leicht from '../assets/images/leicht.svg.png'
import kurtz from '../assets/images/kurtz_ersa.svg.png'
import magna from '../assets/images/magna.svg.png'
import meihiling from '../assets/images/miehling_web-pate.svg.png'
import milk from '../assets/images/milk.svg.png'
import misterbags from '../assets/images/mister_bags.svg.png'
import mitravo from '../assets/images/mitraco-web-paten.svg.png'
import ortel from '../assets/images/ortel-logo-wevb-paten.svg.png'
import mueller from '../assets/images/mueller_reisen-web-paten.svg.png'
import pkt from '../assets/images/pkt.svg.png'
import promo from '../assets/images/promo10---black_web-pate.svg.png'
import pupiplich from '../assets/images/pueplichhuisen-web-paten.svg.png'
import schrema from '../assets/images/schrema.svg.png'
import schlitt from '../assets/images/schlittenhardt.svg.png'
import schroder from '../assets/images/schroeder_baur.svg.png'
import schuelbees from '../assets/images/schuelbees_logo_web-pate.svg.png'
import shimadzu from '../assets/images/shimadzu.svg.png'
import ssb from '../assets/images/ssb-web-paten.svg.png'
import speicherwerke from '../assets/images/speicherwerke.svg.png'
import stepper from '../assets/images/stepper.svg.png'
import sparkase from '../assets/images/sparkasse_pf-calw_web-pate.svg.png'
import mystadtwerk from '../assets/images/mystadtwerk.svg.png'
import swp from '../assets/images/swp-web-pate.svg.png'
import tcw from '../assets/images/tcwolfsberg-web-paten.svg.png'
import toll from '../assets/images/toll.svg.png'
import travelLocal from '../assets/images/travel-local-logo_v2.svg.png'
import vpv from '../assets/images/vpv-logo-web-paten.svg.png'
import vogels from '../assets/images/vogels.png'
import zecha from '../assets/images/zecha-web-pate-2023.svg.png'
import zogi from '../assets/images/zogi.svg.png'
import ideenplus from '../assets/images/ideeenplus.png'
import keterrer from '../assets/images/keterrer.png'
import linda from '../assets/images/linda_apotheken_logo.svg.png'
import nap from '../assets/images/nap.png'
import presentperfect from '../assets/images/presentperfect.svg.png'
import biwe from '../assets/images/biwe_gruppe.svg'
import cb from '../assets/images/cb_web.svg'
import c from '../assets/images/c.svg'
import _4bussines from '../assets/images/4business.svg'
import giving from '../assets/images/giving.svg'
import hauptfleisch from '../assets/images/hauptfleisch.svg'
import hertz_selck from '../assets/images/hertz_selck.svg'
import schulzelogo from '../assets/images/schulzelogo.svg'
import mpk from '../assets/images/mpk.svg'
import pfisterer from '../assets/images/pfisterer.svg'
import rathert from '../assets/images/rathert.png'
import werbepartner from '../assets/images/werbepartner.svg'
import samstag from '../assets/images/samstag.svg'
import schaeffler from '../assets/images/schaeffler.svg'
import kreditpartner from '../assets/images/kreditpartner.svg'
import tsv from '../assets/images/tsv.svg'
import wolfpabich from '../assets/images/wolfpabich.svg'
import zimmermannbrase from '../assets/images/zimmermannbrase.svg'
import zippy from '../assets/images/zippy.svg'
import ytong_bausatzhaus from '../assets/images/ytong_bausatzhaus.svg'
import sparkasse_1 from '../assets/images/sparkasse_1.svg'


// v2
import partnerbanner from '../assets/images/partner-banner.png'
import { useTranslation } from 'react-i18next';


export default function Partners() {

    const { t: tpartners, i18n: i18npartners } = useTranslation('partners');
    const [data, setData] = useState(null)
    const [typeData, setTypeData] = useState(null)

    const currentLanguage = i18npartners.language;

    console.log(currentLanguage)



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://api.zogi-nature.de/list/partner?limit=all&language=${currentLanguage}`, {
                });
                console.log(response);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const TypeData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/list/partner-types?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setTypeData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        TypeData();
        fetchData();
    }, [currentLanguage]);

    console.log('data', data)

    return (
        <>
            {/* banner section */}
            <div className='HeaderBanner_img'>
                <img src={partnerbanner} alt='...' className='w-100' />
            </div>

            <div className='container-fluid '>
                <div className='text-center pt-5 pb-3 '>
                    <p className='text-white'><b>ZOGI NATURE</b></p>
                    <h2 className='text-white'>{tpartners("banner").title1}</h2>
                    <h2 className='' style={{ color: '#CBD62E' }}>{tpartners("banner").title2}</h2>
                </div>
                <div className='container text-white'>
                    <div className='text-center pb-5'>
                        <p className='fw-normal fs-5 w-75 text-center mx-auto'>
                            {tpartners("banner").content}
                        </p>
                    </div>
                </div>
            </div>

            {/* {
                typeData?.data?.map((type) => (
                    <h1>{type.name}</h1>
                ))
            } */}

            {
                typeData?.data?.map((type) => (
                    <div className='' style={{ backgroundColor: '' }}>
                        <div className='container pb-5'>
                            <h2 style={{ color: '#CBD62E', textTransform: 'uppercase' }} className='text-center'> {type.name}</h2>
                            <div className='row mt-5 p-3' style={{ backgroundColor: '#060505' }}>
                                {
                                    data?.data?.filter(item => item.PartnerType.name === type.name)

                                    .map((item) => (
                                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                                            <a href={item.partnerUrl} target='_blank'>
                                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                                    <img src={item.logo} alt='Partner Logo' width={'70%'} height={'auto'} />
                                                    <div className='partner-drop'>
                                                        <p>{item.partnershipYears} {tpartners("body").year}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    ))
                                }
                               
                            </div>
                        </div>
                    </div>
                ))
            }

            {/* <div className='' style={{ backgroundColor: '' }}>
                <div className='container pb-5'>
                    <h2 style={{ color: '#CBD62E', textTransform: 'uppercase' }} className='py-5 text-center'> {tpartners("body").title1}</h2>
                    <div className='row'>
                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.agrar.basf.de/de/Nachhaltigkeit/Bienenschutz/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={basf} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.pistons.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={piston} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.ksc.de/not-in-menue/ksc-tut-gut/soziales-engagement/news/show/article/ksc-tut-gut-uebernimmt-partnerschaft-fuer-ueber-1000m2-bluehwiese-und-bienenvoelker/?type=99' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ksc} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.rewe.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={rewe} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.sparkasse-karlsruhe.de/de/home.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={sparkasse_1} alt='Partner Logo' className='w-50' />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://thost.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={thost} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.ytong.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ytong} alt='Partner Logo' />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-12 col-sm-3 col-md-6 col-lg-3 pb-5'>
                            <a href='https://www.ytong-bausatzhaus.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center partners-card" style={{ width: '19rem', height: '170px', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ytong_bausatzhaus} alt='Partner Logo' className='w-50' />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className='' style={{ backgroundColor: '' }}>
                <div className='container pt-3 pb-5'>
                    <h2 style={{ color: '#CBD62E', textTransform: 'uppercase' }} className='py-3 text-center'>{tpartners("body").title2}</h2>
                    <div className='row mt-5 p-3' style={{ backgroundColor: '#060505' }}>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://aled.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={aled_logo_web} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.biwe.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={biwe} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://cb-praesentwerbung.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={cb} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.mycybergroup.com/de/home' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={c} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://4business-werbeartikel.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={_4bussines} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.givingeurope.com/global/en/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={giving} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.hauptfleisch.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={hauptfleisch} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.hertz-selck.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={hertz_selck} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://schulze-finanzcoaching.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schulzelogo} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://mpk-specialtools.de/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={mpk} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.pfisterer.com/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={pfisterer} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://rathert-creativ.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={rathert} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.werbepartner-renz.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={werbepartner} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.samstag-werbemittel.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={samstag} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.schaeffler.de/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schaeffler} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.s-kreditpartner.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={kreditpartner} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.tsv-auerbach.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={tsv} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://wolf-pabich.de/welcome' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={wolfpabich} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.zimmermann-brase-partner.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={zimmermannbrase} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://zippy-werbemittel.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={zippy} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>


                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://admixx.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={admix} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.bauer-boecker.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={baouer1} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.bauer-walser.net/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={baouer2} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.bike-sport-hoehn.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={hoan} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://bmk-karst.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={bmk} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.brauhaus-20.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={brauhaus} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.breuning.de/nachhaltigkeit.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={breuning} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.bb-schmuck.de/de/bienenpatenschaft-beeswe-love.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={bb} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.casualfood.de/en/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={casualfood} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.c-nw.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={cnw} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://de.crimex.com/werbeartikel/werbemittel-fuer-bienenretter/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={crimex} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://swisssteel-group.com/en' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={duetche} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://digiply.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={digiplywebpate} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://dkpromotion.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={dkpromotion} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.enayati.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={enayati} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://einzigartige-werbeartikel.com/beeswe-love/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={einzigartig} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://die6.de/beeswelove#' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={die6} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={digitalsuedwest} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.eidex.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={eidx} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.fazh.de/startseite' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={fazh} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.flyeralarm.com/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={flyeralrm} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.gadv.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={gavd} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://gww.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={gww} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.gluecksbringer-catering.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={glueksbringer} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.goepi-biomarkt.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={gopi} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.gtue.de/de' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={gtue} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.gvp-energie.de/index.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={gvp} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={haas} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.ivoclar.com/de_de' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={ivoclar} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.jakobs-praesent.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={jakobs} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.koerber-werbemittel.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={kober} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.kolibri.net/ueber-uns/nachhaltigkeit/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={kolibri} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.kurtzersa.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={kurtz} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.magna-sweets.de/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={magna} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://miehling-werbung.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={meihiling} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.milk-food.de/magazin/honig/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={milk} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.misterbags.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={misterbags} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.ortelmobile.de/connect.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={ortel} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://muellerreisen-pf.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={mueller} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.pkt-gmbh.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={pkt} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.promo10.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={promo} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.phuisen.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={pupiplich} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.schrema.de/cgi-bin/lshop.cgi?action=blog&ssl=1&eix=1554375367&ls=de&nocache=1649855113-2136856&eix=1554375367' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schrema} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.schlittenhardt.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schlitt} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.schroeder-baur.de/bienenprojekt/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schroder} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.schuelbe.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={schuelbees} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.ssb-ag.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={ssb} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.speicherwerke.de/index.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={speicherwerke} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.stepper.de/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={stepper} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>4. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.sparkasse-pforzheim-calw.de/de/home.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={sparkase} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.stadtwerke-pforzheim.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={swp} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://tc-wolfsberg.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={tcw} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.tollgroup.com/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={toll} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.travellocal.com/de' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={travelLocal} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.vpv.de/Startseite/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={vpv} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>5. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.vogels.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={vogels} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://www.zecha.de/de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={zecha} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5 pt-5'>
                            <a href='https://zogi.biz/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center mx-auto" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#07121D' }}>
                                    <img src={zogi} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div className='' style={{ backgroundColor: '' }}>
                <div className='container pt-3 pb-5'>
                    <h2 style={{ color: '#CBD62E', textTransform: 'uppercase' }} className='py-3 text-center'>{tpartners("body").title3}</h2>
                    <div className='row mt-5'>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.agrar.basf.de/de/Nachhaltigkeit/Bienenschutz/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={basf} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.casualfood.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={casualfood} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.mycybergroup.com/de/home' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={c} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.ideenplusmarken.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ideenplus} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>2. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.kolibri.net/ueber-uns/nachhaltigkeit/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={kolibri} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>6. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://miehling-werbung.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={meihiling} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://nap-tec.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={nap} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.pistons.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={piston} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.rewe.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={rewe} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.sparkasse-karlsruhe.de/de/home.html' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={sparkasse_1} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.ssb-ag.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ssb} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.stadtwerke-pforzheim.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={swp} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>3. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://thost.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={thost} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://www.ytong.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={ytong} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className='col-6 col-sm-2 col-md-3 col-lg-2 pb-5'>
                            <a href='https://zippy-werbemittel.de/' target='_blank'>
                                <div className="card rounded-0 d-flex justify-content-center align-items-center" style={{ width: '10rem', height: '10rem', position: 'relative', backgroundColor: '#060505' }}>
                                    <img src={zippy} alt='Partner Logo' width={'70%'} height={'auto'} />
                                    <div className='partner-drop'>
                                        <p>1. {tpartners("body").year}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
// export default function Partners() {
//     const { t } = useTranslation();
  
//     return (
//       <div style={{ textAlign: 'center', padding: '100px',color:'#ffff' }}>
//         <h1>{t('pageUnderConstruction', 'Seite im Aufbau')}</h1>
//         <p>{t('checkBackLater', 'Bitte später wiederkommen!')}</p>
//       </div>
//     );
//   }