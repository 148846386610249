import React from 'react'
import imprintBanner from '../assets/images/imprint-banner-img.png'


export default function TermsConditions() {
    return (
        <>
            {/* header section */}
            <div className='imprint-banner' style={{ height: '250px' }}>
                <div className='text-center text-white container' style={{ paddingTop: '130px', position: 'relative', zIndex: 10 }}>
                    <h2 className='text-uppercase'>AGB</h2>
                    {/* <img src={imprintBanner} className='text-line' style={{
                        float: 'right',
                        marginTop: '-101px',
                        width: '159px',
                        marginRight: '131px'
                    }} /> */}
                </div>
            </div>

            {/* contente section */}
            <div className='container imprint-section p-5 text-white'>
                <div className='w-75'>
                    <h5>Allgemeine Geschäftsbedingungen (AGB)</h5>
                    <h3>1. Allgemeines – Geltungsbereich</h3>
                    <p>Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) gelten nur gegenüber Unternehmern, juristischen
                        Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen im Sinne von § 310 i.V. § 14 BGB
                        (nachfolgend als „Kunde/n“ bezeichnet). Verträge über Lieferungen kommen ausschließlich auf der Grundlage der
                        nachstehenden AGB zustande. Entgegenstehende oder von diesen AGB abweichende Bedingungen des Kunden
                        erkennt ZOGI Nature GmbH nicht an, es sei denn, ZOGI Nature GmbH hätte ihrer Geltung ausdrücklich schriftlich
                        zugestimmt. Diese AGB gelten auch dann, wenn ZOGI Nature GmbH in Kenntnis entgegenstehender oder von
                        diesen AGB abweichender Bedingungen des Kunden die Lieferung oder die Leistung an den Kunden vorbehaltlos
                        ausführen. Nebenabreden und abweichende Vereinbarungen bedürfen zu ihrer Rechtswirksamkeit der schriftlichen
                        Bestätigung von ZOGI Nature GmbH. Unsere Verkaufsbedingungen gelten auch für alle künftigen Geschäfte mit
                        dem Kunden.</p>
                    <h3>2. Leistungsbeschreibung, Angebot und Bestellung</h3>
                    <p>Die elektronischen, schriftlichen oder mündlichen Angebote von ZOGI NATURE GMBH stellen kein Angebot im
                        Rechtssinne dar, sondern verstehen sich nur als Aufforderung an den Kunden zur Abgabe einer Bestellung. Ein
                        Vertrag kommt erst mit der schriftlichen Auftragsbestätigung von ZOGI Nature GmbH, spätestens jedoch mit
                        Annahme der Lieferung durch den Kunden zustande. Die Angebote und Auftragsbestätigungen von ZOGI Nature
                        GmbH erfolgen stets unter dem Vorbehalt einer positiven Bonitätsprüfung des Kunden.</p>
                    <h3>3. Mitwirkungspflichten</h3>
                    <p>Der Kunde ist verpflichtet nach dem Vertrag oder nach Treu und Glauben Mitwirkungshandlungen rechtzeitig zu
                        erbringen, insbesondere erforderliche Genehmigungen oder Veredelungsdaten in geeignetem Format einzuholen
                        und bereit zu stellen. Hinsichtlich der Freigaben wird auf Ziff. 8 verwiesen. ZOGI Nature GmbH ist berechtigt, dem
                        Kunden für die Erbringung der Mitwirkungshandlung eine angemessene Frist zu setzen. Nach erfolglosem
                        Fristablauf ist ZOGI Nature GmbH berechtigt, vom Vertrag zurückzutreten und die bisher entstandenen Kosten in
                        Rechnung zu stellen.</p>
                    <h3>4. Lieferung / Lieferzeit</h3>
                    <p>Die Lieferung erfolgt ab Lager Deutschland, solange nichts Anderweitiges vereinbart wurde. Bei Lieferung ab
                        auswärtigem Lager wird die Fracht ab Fabrik berechnet. Der Versand erfolgt auf Kosten und Gefahr des Kunden.
                        Die Gefahr geht spätestens mit der Lieferung ab Werk oder Auslieferungslager auf den Kunden über. Dies gilt auch
                        dann, wenn frachtfreie Zusendung durch ZOGI Nature GmbH vereinbart wurde. Die Ware ist unversichert zu
                        versenden, wenn nichts anderes vereinbart ist. Der Beginn der von ZOGI Nature GmbH angegebenen Lieferzeit
                        setzt die Abklärung aller aus den unter 3. genannten Mitwirkungspflichten des Kunden herrührenden Fragen
                        voraus. Genannte Lieferzeiten sind grundsätzlich unverbindlich, es sei denn die Verbindlichkeit des genannten
                        Zeitpunktes wird durch ZOGI Nature GmbH ausdrücklich und schriftlich bestätigt. Lieferzeitpunkt ist die Absendung
                        ab Werk oder Lager oder es ist, wenn die Ware ohne Verschulden von ZOGI Nature GmbH nicht rechtzeitig
                        abgesendet wird, die Meldung der Versandbereitschaft maßgebend. Für die Dauer der Prüfung von
                        Reinzeichnungen, Vorabmustern, Klischees usw. durch den Kunden gilt die Lieferzeit als unterbrochen und zwar
                        vom Tage der Absendung an den Kunden bis zum Eintreffen seiner schriftlichen Stellungnahme. Die Einhaltung der
                        Lieferverpflichtung von ZOGI Nature GmbH erfolgt vorbehaltlich rechtzeitiger und ordnungsgemäßer
                        Selbstbelieferung und setzt weiter die rechtzeitige und ordnungsgemäße Erfüllung der Verpflichtung des Kunden
                        voraus. Die Einrede des nicht erfüllten Vertrages bleibt vorbehalten. ZOGI Nature GmbH ist zu zumutbaren
                        Teillieferungen und Fakturierung solcher Teillieferungen berechtigt. Kommt der Kunde in Annahmeverzug oder
                        verletzt er schuldhaft sonstige Mitwirkungspflichten, so ist ZOGI Nature GmbH berechtigt, für den entstehenden
                        Schaden, einschließlich etwaiger Mehraufwendungen Ersatz zu verlangen. ZOGI Nature GmbH behält sich weitere
                        Ansprüche vor. Sofern die Voraussetzungen von Abs. (3) vorliegen, geht die Gefahr eines zufälligen Untergangs
                        oder einer zufälligen Verschlechterung der Kaufsache in dem Zeitpunkt auf den Kunden über, in dem dieser in
                        Annahme- oder Schuldnerverzug geraten ist. ZOGI Nature GmbH haftet ferner nach den gesetzlichen
                        Bestimmungen, sofern der Lieferverzug auf einer von ZOGI Nature GmbH zu vertretenden vorsätzlichen oder grob
                        fahrlässigen Vertragsverletzung beruht; ein Verschulden der Vertreter oder Erfüllungsgehilfen von ZOGI Nature
                        GmbH ist ZOGI Nature GmbH zuzurechnen. Sofern der Lieferverzug nicht auf einer von ZOGI Nature GmbH zu
                        vertretenden vorsätzlichen Vertragsverletzung beruht, ist die Schadensersatzhaftung von ZOGI Nature GmbH auf
                        den vorhersehbaren, typischerweise eintretenden Schaden begrenzt. ZOGI Nature GmbH haftet auch nach den
                        gesetzlichen Bestimmungen, soweit der zu vertretende Lieferverzug auf der schuldhaften Verletzung einer
                        wesentlichen Vertragspflicht beruht; in diesem Fall ist aber die Schadensersatzhaftung auf den vorhersehbaren,
                        typischerweise eintretenden Schaden begrenzt.</p>
                    <h3>5. Preise</h3>
                    <p>Sofern sich aus der Auftragsbestätigung nichts anderes ergibt, gelten die Preise von ZOGI Nature GmbH ab Lager
                        Deutschland, ausschließlich Werbeanbringung, Verpackung, Versendungskosten und Zölle. Etwaige Bankgebühren
                        für Auslandsüberweisungen gehen zu Lasten des Kunden. Die gesetzliche Mehrwertsteuer ist nicht in den Preisen
                        von ZOGI NATURE GMBH eingeschlossen, sie wird in gesetzlicher H￶he am Tag der Rechnungsstellung in der
                        Rechnung gesondert ausgewiesen.</p>
                    <h3>6. Zahlungsbedingungen / Aufrechnung / Zurückbehaltung</h3>
                    <p>Sofern sich aus der Auftragsbestätigung nichts anderes ergibt, ist der Kaufpreis netto (ohne Abzug) innerhalb von
                        10 Tagen ab Rechnungsdatum zur Zahlung fällig. Es gelten die gesetzlichen Regeln, betreffend den Folgen des
                        Zahlungsverzugs. Die Zurückhaltung von Zahlungen oder die Aufrechnung wegen etwaiger Gegenansprüche des
                        Kunden ist nur statthaft, wenn es sich um rechtskräftig festgestellte oder von ZOGI NATURE GMBH nicht
                        bestrittene Gegenansprüche handelt.</p>
                    <h3>7. Eigentumsvorbehalt</h3>
                    <p>ZOGI NATURE GMBH behält sich das Eigentum an der Kaufsache bis zum Eingang aller Zahlungen aus dem
                        Liefervertrag vor. Bei vertragswidrigem Verhalten des Kunden, insbesondere bei Zahlungsverzug, ist ZOGI NATURE
                        GMBH berechtigt, die Kaufsache zurückzufordern. In der Zurücknahme der Kaufsache durch ZOGI NATURE GMBH
                        liegt ein Rücktritt vom Vertrag. ZOGI NATURE GMBH ist nach Rücknahme der Kaufsache zu deren Verwertung
                        befugt, der Verwertungserlös ist auf die Verbindlichkeiten des Kunden - abzüglich angemessener
                        Verwertungskosten - anzurechnen. Der Kunde ist verpflichtet, die Kaufsache pfleglich zu behandeln; insbesondere
                        ist er verpflichtet, diese auf eigene Kosten gegen Feuer-, Wasser- und Diebstahlsschäden ausreichend zum Neuwert
                        zu versichern. Bei Pfändungen oder sonstigen Eingriffen Dritter hat der Kunde ZOGI NATURE GMBH unverzüglich
                        schriftlich zu benachrichtigen, damit ZOGI NATURE GMBH Klage gemäß § 771 ZPO erheben kann. Soweit der
                        Dritte nicht in der Lage ist, ZOGI NATURE GMBH die gerichtlichen und außergerichtlichen Kosten einer Klage
                        gemäß § 771 ZPO zu erstatten, haftet der Kunde für den ZOGI NATURE GMBH entstandenen Ausfall. Der Kunde ist
                        berechtigt, die Kaufsache im ordentlichen Geschäftsgang weiter zu verkaufen; er tritt ZOGI NATURE GMBH jedoch
                        bereits jetzt alle Forderungen in Höhe des Faktura-Endbetrages (einschließlich MwSt.) der Forderung von ZOGI
                        NATURE GMBH ab, die ihm aus der Weiterveräußerung gegen seine Abnehmer oder Dritte erwachsen, und zwar
                        unabhängig davon, ob die Kaufsache ohne oder nach Verarbeitung weiterverkauft worden ist. Zur Einziehung dieser
                        Forderung bleibt der Kunde auch nach der Abtretung ermächtigt. Die Befugnis von ZOGI NATURE GMBH, die
                        Forderung selbst einzuziehen, bleibt hiervon unberührt. ZOGI NATURE GMBH verpflichtet sich jedoch, die
                        Forderung nicht einzuziehen, solange der Kunde seinen Zahlungsverpflichtungen aus den vereinnahmten Erlösen
                        nachkommt, nicht in Zahlungsverzug gerät und insbesondere kein Antrag auf Eröffnung eines Vergleichs- oder
                        Insolvenzverfahrens gestellt ist oder Zahlungseinstellung vorliegt. Ist aber dies der Fall, so kann ZOGI NATURE
                        GMBH verlangen, dass der Kunde ZOGI NATURE GMBH die abgetretenen Forderungen und deren Schuldner
                        bekannt gibt, alle zum Einzug erforderlichen Angaben macht, die dazugehörigen Unterlagen aushändigt und den
                        Schuldnern (Dritten) die Abtretung mitteilt. Die Verarbeitung oder Umbildung der Kaufsache durch den Kunden
                        wird stets für ZOGI NATURE GMBH vorgenommen. Wird die Kaufsache mit anderen, ZOGI NATURE GMBH nicht
                        gehörenden Gegenständen verarbeitet, so erwirbt ZOGI NATURE GMBH das Miteigentum an der neuen Sache im
                        Verhältnis des Wertes der Kaufsache (Faktura Endbetrag, einschließlich MwSt.) zu den anderen verarbeiteten
                        Gegenständen zur Zeit der Verarbeitung. Für die durch Verarbeitung entstehende Sache gilt im Übrigen das
                        Gleiche wie für die unter Vorbehalt gelieferte Kaufsache. Wird die Kaufsache mit anderen, ZOGI NATURE GMBH
                        nicht gehörenden Gegenständen untrennbar vermischt, so erwirbt ZOGI NATURE GMBH das Miteigentum an der
                        neuen Sache im Verhältnis des Wertes der Kaufsache (Faktura Endbetrag, einschließlich MwSt.) zu den anderen
                        vermischten Gegenständen zum Zeitpunkt der Vermischung. Erfolgt die Vermischung in der Weise, dass die Sache
                        des Kunden als Hauptsache anzusehen ist, so gilt als vereinbart, dass der Kunde ZOGI NATURE GMBH anteilmäßig
                        Miteigentum überträgt. Der Kunde verwahrt das so entstandene Alleineigentum oder Miteigentum für ZOGI
                        NATURE GMBH. Der Kunde tritt ZOGI NATURE GMBH auch die Forderungen zur Sicherung der Forderungen von
                        ZOGI NATURE GMBH gegen ihn ab, die durch die Verbindung der Kaufsache mit einem Grundstück gegen einen
                        Dritten erwachsen. ZOGI NATURE GMBH verpflichtet sich, die ZOGI NATURE GMBH zustehenden Sicherheiten auf
                        Verlangen des Kunden insoweit freizugeben, als der realisierbare Wert der Sicherheiten von ZOGI NATURE GMBH
                        die zu sichernden Forderungen um mehr als 10% übersteigt; die Auswahl der freizugebenden Sicherheiten obliegt
                        ZOGI NATURE GMBH.</p>
                    <h3>8. Sonderanfertigungen</h3>
                    <p>Bei Aufträgen auf Sonderanfertigung bedürfen alle Angaben über Ausführung, Abmessung usw. ausnahmslos der
                        schriftlichen Bestätigung. Soweit nicht anders vereinbart, verbleibt die Gestaltung bei Eigenentwicklungsaufträgen
                        nach Vorlagen gleich welcher Art bei ZOGI NATURE GMBH. Geringe Abweichungen auch nachfolgender Aufträge
                        können produktionsbedingt sein und werden vom Kunden akzeptiert. Eine Vervielfältigung, Nachbildung oder
                        Weitergabe von ZOGI NATURE GMBH erstellten Zeichnungen, Muster oder Vorlagen ist nur mit ausdrücklicher
                        Zustimmung von ZOGI NATURE GMBH zulässig. Bei Fertigung nach Muster, Zeichnung oder Angaben des
                        Bestellers haftet dieser für eine Verletzung von Schutzrechten Dritter, sowie von Gesetzen, von denen ZOGI
                        NATURE GMBH keine Kenntnis haben kann. Vom Besteller verlangte Muster, Fotos, Montagen oder Entwürfe
                        gehen zu seinen Lasten: Die Produktionsfreigabe hat unverzüglich zu erfolgen. Andernfalls verlängert sich die
                        vereinbarte Lieferzeit entsprechend. Bis zur schriftlichen Freigabe ruht die Fertigung des bestimmten Artikels. Der
                        Kunde erklärt sich mit der Freigabe von digitalem Bildmaterial einverstanden. Mit der Freigabeerklärung geht die
                        Gefahr für etwaige Fehler auf den Besteller über, es sei denn, es handelt sich um grobe Fehler, die von ZOGI
                        NATURE GMBH erkannt werden konnten oder erst bei der anschließenden Fertigung im Werk entstanden sind.
                        Fertigungsbedingte Mehr- oder Minderlieferungen im branchenüblichen Umfang bis zu 10 % der bestellten
                        Mengen gegen Berechnung behält ZOGI NATURE GMBH sich vor. Rechte und Ansprüche an Werkzeugen oder
                        Entwicklungsmodellen bestehen für den Kunden nicht, auch wenn Kostenanteile von ihm vergütet werden. ZOGI
                        NATURE GMBH ist berechtigt, diese Teile innerhalb von 14 Tagen zurückzufordern. Bei nicht erfolgter Rückgabe
                        von Entwicklungsmodellen werden die überlassenen Teile mit dem bei ZOGI NATURE GMBH entstandenen
                        Aufwand in Rechnung gestellt. Das Einverständnis zur Abbildung von Sonderanfertigungen wird vorausgesetzt und
                        bedarf keiner zusätzlichen Genehmigung. Bei Verlust von Filmen oder anderen Vorlagen wird keine Haftung
                        übernommen.</p>
                    <h3>9. Rücksendungen</h3>
                    <p>Rücksendungen werden nur vorbehaltlich der Genehmigung und Prüfung durch ZOGI NATURE GMBH
                        angenommen. Die Annahme einer Rücksendung bedeutet auf keinen Fall eine Anerkennung eines Mangels oder
                        der sonstigen Beanstandung des Kunden. In jedem Fall erfolgt die Rücksendung, auch die des zufälligen
                        Untergangs, auf Gefahr des Kunden. Bei einer Rücksendung, die der Kunde zu vertreten hat, insbesondere, aber
                        nicht ausschließlich, im Falle von Annahmeverweigerungen, wird ZOGI NATURE GMBH eine
                        Wiedereinlagerungspauschale berechnen. Von ZOGI NATURE GMBH gelieferte Ware wird nicht zur Gutschrift
                        zurückgenommen, außer wenn vorher das schriftliche Einverständnis von ZOGI NATURE GMBH eingeholt wurde,
                        die gelieferte Ware standardisierte Lagerware darstellt und anderweitig verwendbar ist. Die Berechnung des
                        Gutschriftbetrages erfolgt anhand einer Bewertung der zurückgenommenen Gegenstände unter Abzug der für den
                        Auftrag und die Bearbeitung der Rücksendung entstandenen Bearbeitungskosten sowie etwaiger Aufwendungen
                        für die Instandsetzung. Der Betrag einer Gutschrift beläuft sich höchstens auf den Preis, der zur Zeit der
                        Rücksendung gilt. Ist der fakturierte Preis niedriger, so stellt dieser den Höchstbetrag dar. Im Falle der Rücknahme
                        hat der Kunde 30 % des Preises, der zur Zeit der Rücksendung gilt, für die erneute Einlagerung der Gegenstände zu
                        bezahlen. Dieser Betrag wird von der Gutschrift ebenfalls in Abzug gebracht.</p>
                    <h3>10. Abtretung</h3>
                    <p>Die Abtretung jeglicher Ansprüche des Kunden gegen ZOGI NATURE GMBH aus der Geschäftsbeziehung bedarf
                        zu deren Wirksamkeit der schriftlichen Zustimmung von ZOGI NATURE GMBH, die ZOGI NATURE GMBH bei
                        berechtigtem Interesse des Kunden nicht unbillig verweigern wird.</p>
                    <h3>11. Mängelhaftung</h3>
                    <p>Die Feststellung offensichtlicher Mängel muss ZOGI NATURE GMBH innerhalb einer Frist von 14 Tagen ab
                        Lieferung der Ware schriftlich angezeigt werden. Die Mängelansprüche des Kunden setzen voraus, dass dieser
                        seinen nach § 377 HGB geschuldeten Unter- suchungs- und Rügeobliegenheiten ordnungsgemäß nachgekommen
                        ist. Soweit ein Mangel der Kaufsache vorliegt, ist ZOGI NATURE GMBH nach Wahl zur Nacherfüllung in Form einer
                        Mängelbeseitigung oder zur Lieferung einer neuen mangelfreien Sache berechtigt. Im Falle der Mängelbeseitigung
                        trägt ZOGI NATURE GMBH die Aufwendungen nur bis zur Höhe des Kaufpreises. Schlägt die Nacherfüllung fehl, so
                        ist der Kunde nach seiner Wahl berechtigt, Rücktritt oder Minderung zu verlangen. Ein Fehlschlagen der
                        Nachbesserung ist erst nach dem erfolglosen zweiten Versuch gegeben. Ergibt die Überprüfung einer
                        Mangelanzeige, dass ein Sachmangel nicht vorliegt, ist ZOGI NATURE GMBH berechtigt dem Kunden eine
                        Aufwands-/ Bearbeitungspauschale in Rechnung zu stellen. Dem Kunden bleibt es in diesem Fall unbenommen,
                        ZOGI NATURE GMBH einen niedrigeren Aufwand als den in Rechnung gestellten, nachzuweisen. Mängelrechte
                        verjähren in 12 Monaten, gerechnet ab Gefahrübergang. In der Bearbeitung einer Mangelanzeige des Kunden
                        durch ZOGI NATURE GMBH ist kein Anerkenntnis des Mangels zu sehen. Die Bearbeitung einer Mangelanzeige
                        führt nur zur Hemmung der Verjährung, soweit die gesetzlichen Voraussetzungen dafür vorliegen. Ein Neubeginn
                        der Verjährung tritt dadurch nicht ein. Dies gilt auch wenn ZOGI NATURE GMBH auf Mangelanzeige des Kunden
                        eine Nacherfüllung (Nachbesserung oder Ersatzlieferung) vornimmt. Eine Nachbesserung kann ausschließlich auf
                        die Verjährung des die Nachbesserung auslösenden Mangels und evtl. im Wege der Nachbesserung neu
                        entstandene Mängel Einfluss haben. Soweit die Ware Gegenstand eines Verbrauchsgüterkaufs ist, bleiben die
                        Rechte des Kunden gem. §§ 478, 479 BGB unberührt, vorausgesetzt der Kunde hat die ihm gem. § 377 HGB
                        obliegenden Untersuchungs- und Rügeobliegenheiten erfüllt.</p>
                    <h3>12. Gesamthaftung</h3>
                    <p>ZOGI NATURE GMBH haftet nach den gesetzlichen Bestimmungen, sofern der Kunde Schadensersatzansprüche
                        geltend macht, die auf Vorsatz oder grober Fahrlässigkeit, einschließlich von Vorsatz oder grober Fahrlässigkeit der
                        Vertreter oder Erfüllungsgehilfen von ZOGI NATURE GMBH beruhen. Soweit ZOGI NATURE GMBH keine
                        vorsätzliche Vertragsverletzung angelastet wird, ist die Schadensersatzhaftung auf den vorhersehbaren,
                        typischerweise eintretenden Schaden begrenzt. ZOGI NATURE GMBH haftet nach den gesetzlichen Bestimmungen,
                        sofern ZOGI NATURE GMBH schuldhaft eine wesentliche Vertragspflicht verletzt, in diesem Fall ist aber die
                        Schadensersatzhaftung auf den vorhersehbaren, typischerweise eintretenden Schaden begrenzt. Soweit dem
                        Kunden ein Anspruch auf Ersatz des Schadens statt der Leistung zusteht, ist die Haftung von ZOGI NATURE GMBH
                        auch im Rahmen von Ziff. 11 auf Ersatz des vorhersehbaren, typischerweise eintretenden Schadens begrenzt. Die
                        Haftung wegen schuldhafter Verletzung des Lebens, des Körpers oder der Gesundheit bleibt unberührt, dies gilt
                        auch für die zwingende Haftung nach dem Produkthaftungsgesetz. Soweit nicht vorstehend etwas Abweichendes
                        geregelt ist, ist die Haftung ausgeschlossen. Soweit die Lieferung unmöglich ist, ist der Kunde berechtigt,
                        Schadensersatz nach den gesetzlichen Bestimmungen zu verlangen. Jedoch beschränkt sich der Anspruch des
                        Kunden auf Schadensersatz neben oder statt der Leistung und auf Ersatz vergeblicher Aufwendungen auf 10 % des
                        Wertes desjenigen Teils der Lieferung, der wegen der Unmöglichkeit nicht genutzt werden kann. Weitergehende
                        Ansprüche des Kunden wegen Unmöglichkeit der Lieferung sind ausgeschlossen. Diese Beschränkung gilt nicht,
                        soweit in Fällen des Vorsatzes, der groben Fahrlässigkeit oder wegen der Verletzung des Lebens, des Körpers oder
                        der Gesundheit gehaftet wird. Das Recht des Kunden zum Rücktritt vom Vertrag bleibt unberührt. Eine Änderung
                        der Beweislast zum Nachteil des Kunden ist mit den vorstehenden Regelungen nicht verbunden.</p>
                    <h3>13. Höhere Gewalt</h3>
                    <p>Ist die Nichteinhaltung von Fristen auf höhere Gewalt, z.B. Pandemien, Mobilmachung, Krieg, Aufruhr, oder auf
                        ähnliche Ereignisse, z.B. Streik oder Aussperrung, zurückzuführen, verlängern sich die Fristen angemessen.</p>
                    <h3>14. Datenschutz</h3>
                    <p>Gemäß § 28 BDSG weist ZOGI NATURE GMBH darauf hin, dass die zur Abwicklung notwendigen Daten
                        gespeichert werden.</p>
                    <h3>15. Schlussbestimmung / Gerichtsstand und Erfüllungsort, anwendbares
                        Recht</h3>
                    <p>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Erfüllungsort ist Keltern.
                        Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit dem Vertrag ist Keltern.</p>
                    <p>Die vom Kunden im Rahmen seiner Bestellung freiwillig mitgeteilten personenbezogenen Daten werden
                        ausschließlich unter Beachtung der Vorgaben des Bundesdatenschutzgesetzes (BDSG), der EU-Verordnung
                        2016/679, Datenschutz - Grundordnung (DSGVO) und des Telemediengesetzes (TMG) verwendet.</p>
                    <p>Sofern eine oder mehrere Bestimmungen dieser Allgemeinen Verkaufsbedingungen unwirksam oder
                        undurchführbar sind oder werden, berührt dies die Wirksamkeit der Geschäftsbedingungen im Übrigen nicht. Die
                        unwirksame oder undurchführbare Bestimmung ist durch eine solche zu ersetzen, die rechtlich oder wirtschaftlich
                        dem am nächsten kommt, was die Parteien mit der ursprünglichen Regelung beabsichtigt haben. Dies gilt auch für
                        etwaige Vertragslücken.</p>
                    <h3>16. Salvatorische Klausel</h3>
                    <p>Sollten einzelne Bestimmungen dieses Vertrages unwirksam oder undurchführbar sein oder nach Vertragsschluss
                        unwirksam oder undurchführbar werden, bleibt davon die Wirksamkeit des Vertrages im Übrigen unberührt.</p>
                    <p>Stand Dezember/2022</p>
                </div>
            </div>
        </>
    )
}
