import React from 'react'
import '../assets/css/ShopNow.css'
import logoimg from '../assets/images/section-bg-banner.png'

export default function ShopNow() {
    return (
        <>
            <div className='shop-banner' style={{ height: '300px' }}>
                <div className='text-center text-white' style={{ paddingTop: '150px', position: 'relative', zIndex: 10 }}>
                    <h1>ZOGI NATURE SHOP</h1>
                </div>
            </div>

            <div className='container shop-section p-5 text-center' style={{color:'#7D7D7D'}}>
                <img src={logoimg} className='' style={{width:'17%'}} />
                <div className='mt-3'>
                    <p className='fs-4'>Zogi Nature Web Shop</p>
                    <h1 className='display-4 text-white'>COMING SOON...</h1>
                </div>
            </div>
        </>
    )
}
