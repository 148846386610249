import React, { useEffect, useState } from 'react'
import '../assets/css/SpecialEvents.css';
import splBanner from '../assets/images/spl-banner.png'



import { useRef, } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';


import splcarimg1 from '../assets/images/spl-car-img1.png';
import splcarimg2 from '../assets/images/spl-car-img2.png';
import splcarimg3 from '../assets/images/spl-car-img3.png';
import splcarimg4 from '../assets/images/spl-car-img4.png';
import splcarimg5 from '../assets/images/spl-car-img5.png';
import splcarimg6 from '../assets/images/spl-car-img6.png';
import splcarimg7 from '../assets/images/spl-car-img7.png';



// v2
import spleventBanner from '../assets/images/spleventBanner.png'
import splinfo from '../assets/images/splinfo.png'
import splimkereisymposium from '../assets/images/spl-imkerei-symposium.png'
import splimkereiimkerworks from '../assets/images/spl-imkerei-imkerworks.png'
import splimkereicatring from '../assets/images/spl-imkerei-catring.png'
import splimkereikundenbesuche from '../assets/images/spl-imkerei-kundenbesuche.png'
import splimkreationerlebnistage from '../assets/images/spl-kreation-erlebnistage.png'
import splimkereibbqs from '../assets/images/spl-imkerei-bbqs.png'
import splimkereikundenbesuche2 from '../assets/images/spl-imkerei-kundenbesuche2.png'
import splimkereikundenbesuche3 from '../assets/images/spl-imkerei-kundenbesuche3.png'
import splimkereibbqs2 from '../assets/images/spl-imkerei-bbqs2.png'
import { useTranslation } from 'react-i18next';


export default function SpecialEvents() {

    const { t: tspecial_events, i18n: i18nspecial_events } = useTranslation('special_events');
    const [data, setData] = useState(null)


    const currentLanguage = i18nspecial_events.language;

    console.log('data', process.env.REACT_APP_API_BASE_URL)


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/special_events/list?language=${currentLanguage}`, {
                });
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

    }, [currentLanguage])


    return (
        <>
            {/* banner section */}
            <div className='HeaderBanner_img'>
                <img src={spleventBanner} alt='...' className='w-100' />
            </div>

            <div className='container text-white'>
                <div className='pt-5 pb-5 mt-5' id='carpetary' >
                    <div className='row w-100'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6'>
                            <img src={splinfo} class="d-block w-100" alt="..." />
                        </div>
                        <div className='col d-flex align-items-center'>
                            <div className="ps-4">
                                <h2 className='' style={{ color: '#CBD62E' }}>{tspecial_events("banner").title}</h2>
                                <p className='mt-3 fs-5'>
                                    {tspecial_events("banner").content}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <hr style={{ border: '2px solid #CBD62E', width: '10%', opacity: 1 }} className='m-0 mx-auto my-3' />
                <h2 className='text-center ' style={{ color: '#CBD62E' }}>{tspecial_events("gallary_section").title}</h2>
                <div className='row text-white'>

                    {
                        data?.data?.map((item) => (
                            <div className='col-6 col-md-4 mt-4'>
                                <img src={item.imageUrl} className='w-100' />
                                <h5 className='mt-3'>{item.title}</h5>
                                <p className=''>{item.SpecialEventsType.name}</p>
                            </div>
                        ))
                    }
                    {/* <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereisymposium} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary1_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary1_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereiimkerworks} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary2_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary2_subtitle}</p>

                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereicatring} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary3_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary3_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereikundenbesuche} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary4_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary4_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkreationerlebnistage} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary5_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary5_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereibbqs} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary6_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary6_subtitle}</p>
                    </div>

                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereikundenbesuche2} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary7_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary7_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereikundenbesuche3} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary8_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary8_subtitle}</p>
                    </div>
                    <div className='col-6 col-md-4 mt-4'>
                        <img src={splimkereibbqs2} className='w-100' />
                        <h5 className='mt-3'>{tspecial_events("gallary_section").gallary9_title}</h5>
                        <p className=''>{tspecial_events("gallary_section").gallary9_subtitle}</p>
                    </div> */}
                </div>
            </div>
        </>
    )
}
